import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
const style = document.createElement('link');
style.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
style.rel = 'stylesheet';

const style2 = document.createElement('link');
style2.href = 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700';
style2.rel = 'stylesheet';

const style3 = document.createElement('link');
style3.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
style3.rel = 'stylesheet';

const style4 = document.createElement('link');
style4.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
style4.rel = 'stylesheet';

const style5 = document.createElement('script');
style5.src = 'https://cdn.jsdelivr.net/npm/clappr@latest/dist/clappr.min.js';
style5.type = 'text/javascript';

const style6 = document.createElement('script');
style6.src = 'https://code.jquery.com/jquery-3.4.1.slim.min.js';
style6.type = 'text/javascript';

const style7 = document.createElement('script');
style7.src = 'https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js';
style7.type = 'text/javascript';

const style8 = document.createElement('script');
style8.src = 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js';
style8.type = 'text/javascript';

const style9 = document.createElement('link');
style9.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css';
style9.rel = 'stylesheet';

document.getElementsByTagName('head')[0].appendChild(style);
document.getElementsByTagName('head')[0].appendChild(style2);
document.getElementsByTagName('head')[0].appendChild(style3);

document.getElementsByTagName('head')[0].appendChild(style4);

document.getElementsByTagName('head')[0].appendChild(style5);
document.getElementsByTagName('head')[0].appendChild(style6);
//  document.getElementsByTagName("head")[0].appendChild(style7)
//  document.getElementsByTagName("head")[0].appendChild(style8)
//  document.getElementsByTagName("head")[0].appendChild(style9)
