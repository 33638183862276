import React from 'react';
import Modal from 'react-modal';
import Spinner from 'react-spinner-material';
// import './styles.css';

export default function Loading({ open, text, theme }) {
  return (
    <Modal
      isOpen={open}
      style={{

        overlay: {
          backgroundColor: 'rgba(250, 250, 250, 1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9,
        },
      }}
      className="loading"
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner
          size={50}
          spinnerColor="#faa82f"
          spinnerWidth={3}
          visible
        />

      </div>

      <p className="loading__message">{text}</p>
    </Modal>
  );
}
