import React, { useEffect, useRef, useState } from 'react';
import {
  Player, BigPlayButton, LoadingSpinner, ControlBar,
} from 'video-react';
import Hls from 'hls.js';
import Loading from '../../Loading';
import Header from '../../Header';
import Footer from '../../Footer';
import '../../../../node_modules/video-react/dist/video-react.css';
import Axios from '../../../Axios';
import { isAuthenticated, getAccountType } from '../../../Services/token';

export default function MovieLocation({ match }) {
  const [loading, setLoading] = React.useState(false);
  const [url, setMovieUrl] = React.useState(null);
  const [audioTracks, setAudioTracks] = useState([]);
  const [qualityLevels, setQualityLevels] = useState([]);
  const [hlsJSRef, setHlsJSRef] = useState();
  const playerRef = useRef(null);

  function getActualMovie() {
    return new Promise((resolve, reject) => {
      Axios.get(`/movie/getMovie/${match.params.id}`)
        .then((resp) => {
          const { data } = resp;
          resolve(data.response.message);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        });
    });
  }

  async function fetchMovie() {
    const { movie_url } = await getActualMovie();

    setLoading(false);
    setMovieUrl(movie_url);
    const hlsUrl = movie_url;
    const video = playerRef.current;
    video.src = hlsUrl;
    video.addEventListener('loadedmetadata', () => {
      video.play();
    });
    if (Hls.isSupported()) {
      // If the browser supports MSE, use hls.js to play the video
      const hls = new Hls();
      setHlsJSRef(hls);
      hls.loadSource(hlsUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
      hls.on(Hls.Events.BUFFER_CREATED, () => {
        console.log(hls.levels);
        setAudioTracks(hls.audioTracks);
        setQualityLevels(hls.levels);
      });
    } else {
      alert('Please use a modern browser to play the video');
    }
  }

  function returnQualitySufix(height) {
    if (height >= 1080) return '(Full HD)';
    if (height >= 720) return '(HD)';
    return '';
  }

  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(true);
      Axios.get('/usuario/assinante')
        .then((resp) => {
          const { data } = resp;
          if (data.response.message === 'ACTIVE') {
            fetchMovie();
          } else {
            window.location = '#/assinar';
          }
        })
        .catch((error) => {
          console.error(error);
          window.location = '#/';
          setLoading(false);
        });
    } else {
      window.location = '#/login';
    }
  }, []);

  return (
    <>

      <Header />
      <Loading
        open={loading}
        text=""
        theme="white"
      />
      <div className="">
        <div className="video-container">
          <div
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
            }}
          >
            {
              !!url
              && (
                <video
                  controls
                  ref={playerRef}
                  autoPlay
                  className="video-playerHLS"
                />
              )
            }
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '95%',
            }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Idioma :</span>
                <select onChange={e => hlsJSRef.audioTrack(e.target.value)} style={{ marginTop: 15 }}>
                  {
                    audioTracks.filter(x => x.groupId === 'stereo').map((track, index) => (
                      <option value={track.id} style={{ backgroundColor: '#e76115', marginLeft: index > 0 ? 10 : 0 }} className="btn btn-default">
                        {
                          track.name
                        }
                      </option>
                    ))
                  }
                </select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                <span style={{ color: 'white', fontWeight: 'bold' }}>Qualidade :</span>
                <select onChange={e => hlsJSRef.currentLevel = e.target.value} style={{ marginTop: 15 }}>
                  <option value="-1">Auto</option>
                  {
                    qualityLevels.sort((a, b) => b.height - a.height).map((track, index) => (
                      <option value={index} style={{ backgroundColor: '#e76115', marginLeft: index > 0 ? 10 : 0 }} className="btn btn-default">
                        {
                          track.height
                        }
                        p
                        {' '}
                        {returnQualitySufix(track.height)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
