import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Axios from '../../Axios';
import Loading from '../../Components/Loading';
import SnackBar from '../../Components/SnackBar';

export default function Profile() {
  const [snack, setSnack] = React.useState({
    open: false,
    variant: 'error',
    message: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState(null);

  const [fullname, setFullname] = useState('');
  const [adress, setAdress] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [country, setCountry] = useState('');
  const [cep, setCep] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  React.useEffect(() => {
    getProfileData();
  }, []);

  function getProfileData() {
    setLoading(true);
    Axios.get('/usuario')
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          setLoading(false);
          setProfile(data.response.message);

          const {
            fullname,
            email,
            adress,
            city,
            neighborhood,
            country,
            cep,
            cpf,
            phone,
          } = data.response.message;

          setFullname(fullname);
          setAdress(adress);
          setCity(city);
          setNeighborhood(neighborhood);
          setCountry(country);
          setCep(cep);
          setCpf(cpf);
          setPhone(phone);
          setEmail(email);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function updateUserData() {
    setLoading(true);
    Axios.post('/usuario/editar', {
      fullname,
      adress,
      city,
      neighborhood,
      country,
      cep,
      cpf,
      phone,
      email,
    })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          setLoading(false);
          setProfile(data.response.message);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Header />
      <div className="">
        <div className="wrapper">
          <div className="container section remove-top-padding">
            <div className="row" style={{ margin: '50px auto' }}>
              <div className="col-sm-12" id="">
                <div
                  className="row col-md-6 purchase-info"
                  style={{ margin: '50px auto;' }}
                >
                  <div className="col-sm-12">
                    <h2>Dados de Assinatura</h2>
                    {profile && (
                      <table className="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th>Tipo de Assinatura</th>
                            <th>Duração</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {profile.type_account === 'Free' ? (
                              <>
                                <td>Não assinante</td>
                                <td>---</td>

                              </>
                            ) : (
                              <>
                                <td>Assinante</td>
                                <td>1 mês</td>

                              </>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {profile?.type_account === 'Free' ? (
                      <div className="form-group">
                        <Link to="/pagamento/assinatura">
                          <Button
                            type="submit"
                            className="btn btn-primary"
                            id="submit-btn"
                            style={{ margin: '0 0 2rem 0' }}
                          >
                            Assinar
                          </Button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>

              </div>
              <div className="col-sm-12" id="">
                <h2> Seus Dados</h2>
                <p style={{ 'padding-left': '2%' }}>Dados completos</p>
                {profile && (
                  <form>
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="completeName"
                        className="col-md-9 control-label"
                      >
                        Nome Completo
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="completeName"
                          name="completeName"
                          onChange={e => setFullname(e.target.value)}
                          type="text"
                          value={fullname}
                          placeholder="Digite seu nome completo"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email" className="col-md-9 control-label">
                        E-mail
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="email"
                          name="email"
                          onChange={e => setEmail(e.target.value)}
                          type="email"
                          value={email}
                          placeholder="Digite seu e-mail"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phone" className="col-md-9 control-label">
                        Telefone para Contato
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="phone"
                          type="text"
                          className="form-register"
                          onChange={e => setPhone(e.target.value)}
                          name="phone"
                          value={phone}
                          placeholder="Digite seu nome de usuário"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="cep" className="col-md-9 control-label">
                        CEP
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="cep"
                          name="cep"
                          type="number"
                          value={cep}
                          placeholder="Sem pontos ou tracos"
                          onChange={e => setCep(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label
                        htmlFor="adress"
                        className="col-md-9 control-label"
                      >
                        Endereco
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="adress"
                          name="adress"
                          type="text"
                          value={adress}
                          placeholder="Digite seu endereco"
                          onChange={e => setAdress(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label
                        htmlFor="neighborhood"
                        className="col-md-9 control-label"
                      >
                        Bairro
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="neighborhood"
                          name="neighborhood"
                          type="text"
                          value={neighborhood}
                          placeholder="Digite seu bairro"
                          onChange={e => setNeighborhood(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="city" className="col-md-9 control-label">
                        Cidade
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="city"
                          name="city"
                          type="text"
                          value={city}
                          onChange={e => setCity(e.target.value)}
                          placeholder="Cidade"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="state" className="col-md-9 control-label">
                        País
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="state"
                          name="state"
                          type="text"
                          value={country}
                          onChange={e => setCountry(e.target.value)}
                          placeholder="UF"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="cpf" className="col-md-2 control-label">
                        CPF
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="cpf"
                          type="number"
                          className="form-register"
                          name="cpf"
                          value={cpf}
                          onChange={e => setCpf(e.target.value)}
                          placeholder="Digite seu CPF(sem pontos ou tracos)"
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="form-group">
                      <div className="col-md-12">
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-btn"
                          onClick={() => updateUserData()}
                          style={{ width: '100% !important;' }}
                        >
                          Atualizar
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading
        open={loading}
        text="Estamos carregando seu perfil..."
        theme="white"
      />
      {snack && (
        <SnackBar
          isOpen={snack.open}
          isVariant={snack.variant}
          isMessage={snack.message}
          isClose={() => setSnack(false)}
        />
      )}
      <Footer />
    </>
  );
}
