import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function Privacity() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div>
        <div className="">
          <div id="app-show-movie" className="wrapper">
            <div className="container section single-movie">
              <h2>Política de Privacidade</h2>
              <div className="faq-item-wrapper">
                <div className="faq-item">
                  <span>
                    Esta Declaração de privacidade explica as práticas da VamuveTV, incluindo as suas
                    escolhas como cliente, referentes à coleta, utilização e divulgação de certas
                    informações, incluindo seus dados pessoais, em relação ao serviço VamuveTV.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como contatar a VamuveTV?</h3>
                  <span>
                    Caso tenha dúvidas gerais sobre sua conta ou queira saber como entrar contato com o atendimento
                    ao cliente, para questões específicas sobre esta Declaração de privacidade, incluindo a
                    utilização de dados pessoais, cookies e outras tecnologias semelhantes, entre em contato
                    com o nosso Encarregado de Proteção de Dados/Divisão de Privacidade por email no
                    endereço:
                    {' '}
                    <a href="mailto:privacidade@vamuvetv.com.br">privacidade@vamuvetv.com.br</a>
                    <br />
                    <br />
                    A VamuveTV produções e Empreendimentos, Eireli-ME é a entidade responsável pela plataforma
                    e tratamento dos seus dados pessoais. Se você entrar em contato para solicitar assistência,
                    para sua segurança e da VamuveTV, poderá ser preciso autenticar sua identidade antes de
                    atender ao seu pedido.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Coleta de dados</h3>
                  <span>
                    A VamuveTV recebe e armazena dados sobre você, tais como:
                    <br />
                    <br />
                    <ol style={{
                      listStyle: 'lower-alpha',
                    }}
                    >
                      <li>
                        <strong>Dados que você nos fornece:</strong>
                        {' '}
                        a VamuveTV coleta os dados que você nos fornece, tais como:
                        <br />
                        <ul style={{
                          listStyleType: 'disc',
                        }}
                        >
                          <li>
                            Os dados cadastrais que você disponibilizou quando contratou os nossos serviços, como por
                            exemplo, seu nome, CPF, endereço físico e de e-mail, número(s) de telefone, e outros
                            identificadores que você pode utilizar (
                            <em>
                              como o nome que você usa em áreas disponíveis como jogos ou passatempos
                            </em>
                            ). Em certos países, coletamos um número de identificação nacional
                            para fins de cobrança e cumprimento das leis tributárias. Os dados são coletados de diversas
                            formas, incluindo quando você os fornece ao usar o serviço, interage com o serviço de
                            atendimento ao cliente da VamuveTV ou participa de pesquisas ou promoções de marketing;
                          </li>
                          <li>
                            Informações referentes à sua classificação de títulos, suas preferências pessoais e
                            configurações (
                            <em>inclusive preferências configuradas na seção “Perfil” no nosso site</em>
                            ) ou,
                            de outra forma, quando você fornece dados à VamuveTV, seja por intermédio do serviço VamuveTV ou outros.
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <strong>Dados que coletamos automaticamente:</strong>
                        {' '}
                        a VamuveTV coleta dados sobre você e seu uso do nosso serviço, suas interações com a
                        VamuveTV e a publicidade da VamuveTV, assim como dados sobre sua rede, seus aparelhos
                        de rede e seu computador ou outros aparelhos compatíveis com a VamuveTV que você possa
                        usar para acessar nossos serviços (tais como, videogames, Smart TVs, aparelhos móveis,
                        decodificadores e outros aparelhos de streaming). Tais dados incluem:
                        <br />
                        <ul style={{
                          listStyleType: 'disc',
                        }}
                        >
                          <li>
                            suas atividades no serviço VamuveTV, como os títulos selecionados, os filmes, séries,
                            desenhos ou demais obras audiovisuais a que você assistiu, buscas bem como as atividades nos jogos da VamuveTV;
                          </li>
                          <li>
                            suas interações com nossos emails, nossas mensagens de texto e nossas mensagens enviadas
                            por canais de comunicação push e online;
                          </li>
                          <li>
                            detalhes sobre suas interações com o serviço de atendimento ao cliente, tais como data,
                            hora e motivo do contato, transcrição de comunicação pelo bate-papo e, no caso de chamadas,
                            o seu número de telefone e as gravações das chamadas;
                          </li>
                          <li>
                            IDs de aparelhos ou outros identificadores únicos, incluindo seus aparelhos de rede e
                            aparelhos compatíveis com a VamuveTV em sua rede Wi-Fi;
                          </li>
                          <li>
                            Endereço de protocolo de internet (IP), bem como os volumes de dados trafegados na
                            internet da rede 2G, 3G e ou/4G ou outras que se fizerem disponíveis relacionadas
                            ao seu acesso em nossos sistemas;
                          </li>
                          <li>
                            identificadores de aparelho redefiníveis (
                            <em>também conhecidos como identificadores de publicidade</em>
                            ), como os incluídos em certos celulares, tablets e aparelhos de streaming
                            (consulte a seção “Cookies e publicidade na Internet” abaixo para saber mais);
                          </li>
                          <li>
                            características de aparelhos e software (tais como o tipo e a configuração), dados
                            sobre a conexão, incluindo o tipo (Wi-Fi, celular), estatísticas sobre visualizações
                            de página, fonte encaminhadora (por exemplo, URLs de origem), endereço de IP (que pode
                            indicar à VamuveTV sua localização geográfica aproximada), navegador e dados padrão de
                            logs de servidores da Internet;
                          </li>
                          <li>
                            dados coletados pelo uso de cookies, web beacons e outras tecnologias, incluindo dados de
                            anúncios (tais como dados sobre a disponibilidade e distribuição de anúncios, a URL do site,
                            assim como a data e hora). (
                            <em>Consulte a seção “Cookies e publicidade na Internet” para saber mais.</em>
                            );
                          </li>
                          <li>
                            As informações relacionadas com o consumo, fatura e pagamento dos serviços contratados por você;
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <strong>Dados obtidos com parceiros:</strong>
                        {' '}
                        a VamuveTV coleta dados a partir de outras empresas com as quais você mantém um relacionamento
                        (“
                        <em>Parceiros</em>
                        ”). Esses Parceiros podem incluir (
                        <em>dependendo dos serviços que você usa</em>
                        ): o seu provedor de serviços de TV ou Internet, ou outros provedores de aparelhos de
                        streaming que disponibilizam o nosso serviço no aparelho deles; operadoras de telefonia
                        celular ou outras empresas que fornecem serviços e recebem o pagamento pelo serviço VamuveTV
                        e o repassam para nós ou oferecem promoções pré-pagas para o serviço VamuveTV; e provedores
                        de plataformas de assistente de voz que permitem a interação com o nosso serviço por meio
                        de comandos de voz. Os dados fornecidos pelos Parceiros para nós dependem da natureza
                        dos serviços do Parceiro e podem incluir:
                        <br />
                        <ul style={{
                          listStyleType: 'disc',
                        }}
                        >
                          <li>
                            consultas e comandos de busca aplicáveis à VamuveTV realizados em aparelhos ou plataformas de assistente de voz do Parceiro;
                          </li>
                          <li>
                            dados de ativação de serviço, como o seu email ou outros dados de contato;
                          </li>
                          <li>
                            endereços IP, IDs de aparelho ou outros identificadores únicos, bem como dados associados sobre promoções pré-pagas, faturamento e interface do usuário, usados na autenticação de usuários, na experiência de assinatura do serviço VamuveTV, no processamento de pagamentos pelo Parceiro e na exibição de conteúdo da VamuveTV para você por meio de partes da interface do usuário do Parceiro.
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        <strong>Dados de outras fontes:</strong>
                        {' '}
                        a VamuveTV também obtém dados a partir de outras fontes. Protegemos esses dados de acordo
                        com as práticas descritas nesta Declaração de privacidade, além de respeitar as restrições
                        adicionais impostas pela fonte dos dados. Essas fontes variam no decorrer do tempo,
                        mas podem incluir:
                        <br />
                        <ul style={{
                          listStyleType: 'disc',
                        }}
                        >
                          <li>
                            prestadores de serviços que nos ajudam a identificar uma localização baseada no seu endereço IP para personalizar o nosso serviço e para outros fins compatíveis com esta Declaração de privacidade;
                          </li>
                          <li>
                            prestadores de serviços de segurança que nos fornecem dados para proteger nossos sistemas, previnem fraudes e nos ajudam a proteger a segurança das contas VamuveTV;
                          </li>
                          <li>
                            prestadores de serviços de pagamento que nos fornecem dados de pagamento ou saldo, ou atualizações desses dados com base no relacionamento deles com você;
                          </li>
                          <li>
                            fornecedores de dados online e offline, dos quais obtemos dados demográficos agregados, dados baseados no interesse e dados relacionados a anúncios online;
                          </li>
                          <li>
                            fontes publicamente disponíveis, como publicações disponibilizadas ao público em plataformas de redes sociais e por meio de bancos de dados públicos que associam endereços IP a provedores de Internet;
                          </li>
                          <li>
                            serviços de terceiros usados por você que oferecem funções nos jogos da VamuveTV, como jogar com vários jogadores, rankings e opções para salvar jogos.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Uso de dados</h3>
                  <span>
                    A VamuveTV utiliza os dados para oferecer, analisar, administrar, aprimorar
                    e personalizar nossos serviços e esforços de marketing, para gerenciar encaminhamentos
                    de assinantes, para processar sua inscrição, seus pedidos e pagamentos, e para nos
                    comunicarmos com você sobre esses e outros assuntos. Por exemplo, a VamuveTV utiliza
                    esses dados para:
                    <br />
                    <br />
                    <ul>
                      <li>
                        determinar sua localização geográfica aproximada, oferecer conteúdo localizado, oferecer
                        recomendações personalizadas e customizadas de filmes, séries, desenhos e demais obras
                        audiovisuais que, na nossa avaliação, poderiam ser do seu interesse, determinar o seu
                        provedor de Internet para auxiliar na resolução de problemas de rede para você
                        (
                        <em>também usamos dados agregados do provedor de Internet para fins operacionais e comerciais</em>
                        )
                        e ajudar nossa equipe a responder de forma rápida e eficiente às suas dúvidas e solicitações;
                      </li>
                      <li>
                        coordenar com os Parceiros a disponibilização do serviço VamuveTV para os assinantes e fornecer
                        aos não assinantes dados sobre a disponibilidade do serviço VamuveTV de acordo com a relação
                        específica que você mantém com o Parceiro;
                      </li>
                      <li>
                        proteger nossos sistemas, prevenir fraudes e nos ajudar a manter seguras as contas da VamuveTV;
                      </li>
                      <li>
                        prevenir, detectar e investigar atividades possivelmente proibidas ou ilegais, incluindo atividades
                        fraudulentas, e aplicar nossos termos (
                        <em>
                          tais como determinar se você se qualifica para ofertas de
                          inscrição na VamuveTV e a quais ofertas essa qualificação se aplica e determinar se um aparelho
                          em particular pode ser usado com a conta segundo os nossos Termos de uso
                        </em>
                        );
                      </li>
                      <li>
                        analisar e entender nosso público, melhorar o serviço (
                        <em>inclusive a interface do usuário e o desempenho do serviço</em>
                        ) e otimizar a seleção de conteúdo, os algoritmos de recomendação e a transmissão.
                        Nosso sistema de recomendações tenta prever o que você gostaria de assistir quando
                        entra na VamuveTV;
                      </li>
                      <li>
                        comunicar-se com você sobre o serviço para que possamos enviar novidades sobre a VamuveTV,
                        detalhes sobre novas funcionalidades, conteúdos disponíveis na VamuveTV, ofertas especiais,
                        anúncios sobre promoções, pesquisas de mercado, e para prestar ajuda com pedidos de natureza
                        operacional, como pedidos de redefinição de senha. Essas comunicações podem ser feitas por vários
                        métodos, tais como email, notificações push, mensagens de texto, canais de mensagens online e
                        comunicações de identificadores correspondentes.
                      </li>
                    </ul>
                    <br />
                    Além disso, podemos tratar os seus dados pessoais para:
                    <br />
                    <br />
                    <ul>
                      <li>
                        Cumprimento de obrigações legais ou regulatórias;
                      </li>
                      <li>
                        Aprimorar o desempenho e garantir a segurança das nossas redes e sistemas;
                      </li>
                      <li>
                        Realizar análises estatísticas que permitam avaliar a demanda por região geográfica;
                      </li>
                      <li>
                        Identificar e corrigir falhas nos serviços da VamuveTV;
                      </li>
                      <li>
                        Aumentar a qualidade dos nossos serviços;
                      </li>
                      <li>
                        Desenvolver e distribuir novos Produtos e Serviços;
                      </li>
                      <li>
                        Fazer pesquisas de satisfação diretamente ou através de nossos parceiros.
                      </li>
                    </ul>
                    Informamos que o tratamento de dados pessoais poderá ser realizado de forma customizada
                    para melhoria das ofertas dos nossos Produtos e Serviços.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Divulgação de dados</h3>
                  <span>
                    A VamuveTV divulgará seus dados para fins específicos e a terceiros, conforme descrição abaixo:
                    <br />
                    <br />
                    <ul>
                      <li>
                        <strong>Grupo VamuveTV de empresas:</strong>
                        {' '}
                        A VamuveTV poderá compartilhar os seus dados nos casos que envolvam a fusão, cisão, aquisição ou incorporação
                        de empresas ou qualquer outra operação societária em que a VamuveTV seja parte, para: proporcionar acesso a
                        nossos serviços; proporcionar serviço de atendimento ao cliente; tomar decisões sobre melhorias ao serviço,
                        desenvolvimento de conteúdo e outros fins descritos na seção “Uso de dados” da presente Declaração de privacidade.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Prestadores de Serviços:</strong>
                        {' '}
                        a VamuveTV poderá contratar outras empresas, agentes ou terceirizados (
                        <em>os “Prestadores de Serviços”</em>
                        ) para prestar serviços em nome da VamuveTV ou ajudar a VamuveTV na prestação de serviços destinados
                        a você. Por exemplo, a VamuveTV contrata Prestadores de Serviços para prestar serviços de marketing,
                        publicidade, comunicação, segurança, infraestrutura e serviços de TI para personalizar e otimizar o
                        serviço VamuveTV, fornecer dados de conta bancária ou de saldo, processar transações por cartão de crédito
                        e outras formas de pagamento, prestar serviços de atendimento ao cliente, analisar e aprimorar dados(
                        <em>inclusive dados de interação com o serviço VamuveTV</em>
                        ) e conduzir pesquisas de mercado. No decorrer da prestação desses serviços, esses Prestadores de Serviços
                        podem ter acesso a seus dados pessoais ou de outra natureza. Não autorizamos estas empresas a usar ou divulgar
                        seus dados pessoais, a não ser com a finalidade de prestar os serviços contratados pela
                        VamuveTV (
                        <em>que incluem a manutenção e o aprimoramento de seus serviços</em>
                        ).
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Parceiros:</strong>
                        {' '}
                        como descrito acima, você pode ter um relacionamento com um ou mais de nossos Parceiros. Nesse caso,
                        poderemos compartilhar determinados dados com esses Parceiros para coordenar com eles a prestação do
                        serviço VamuveTV aos assinantes e fornecer dados sobre a disponibilidade do serviço VamuveTV. Por exemplo,
                        dependendo de quais serviços de Parceiros você usa, poderemos compartilhar dados:
                        <ol style={{
                          listStyle: 'lower-alpha',
                        }}
                        >
                          <li>
                            para facilitar as promoções pré-pagas do Parceiro ou o recebimento do pagamento do serviço ou
                            Produtos VamuveTV pelo Parceiro e o repasse desse pagamento para a VamuveTV;
                          </li>
                          <li>
                            com Parceiros que operam plataformas de assistente de voz que permitem interagir com o nosso
                            serviço por meio de comandos de voz;
                          </li>
                          <li>
                            para que seja possível sugerir a você, na interface de usuário do Parceiro, o conteúdo e os
                            recursos disponíveis no serviço VamuveTV. Para os assinantes, essas sugestões fazem parte do serviço VamuveTV
                            e podem incluir recomendações personalizadas de conteúdo a ser assistido.
                          </li>
                        </ol>
                        <br />
                      </li>
                      <li>
                        <strong>Ofertas promocionais:</strong>
                        {' '}
                        a VamuveTV poderá oferecer programas, produtos ou promoções conjuntas que, para efeitos de
                        participação, exijam que dados pessoais sejam compartilhados com terceiros. Para realizar estes
                        tipos de promoção, a VamuveTV poderá compartilhar seu nome e outros dados pessoais referentes ao
                        benefício que estamos oferecendo. Para tanto, nossos sites e aplicativos poderão conter links para
                        outros sites que não possuímos ou operamos, inclusive links de anunciantes ou parceiros autorizados
                        a utilizar nossa logomarca. Note que dentro destes sites e aplicativos Você estará sujeito a outros
                        Termos de Uso e Políticas de Privacidade. Ademais, temos Produtos e Serviços que tem sua Política de
                        Privacidade ou Termo de Uso próprios, como por exemplo, aplicativos e produtos próprios, marketplace,
                        entre outros. Lembramos que esta Política é válida somente para a VamuveTV, seus fornecedores e parceiros,
                        portanto, suas regras não se aplicam a sites e aplicativos de terceiros. Ainda, é importante ressaltar
                        que a existência desses links não significa nenhuma relação de endosso ou de patrocínio entre a VamuveTV
                        e esses terceiros, assim, não temos nenhuma responsabilidade com relação a esses, inclusive em relação a
                        coleta e tratamento de dados pessoais e na utilização de cookies realizados por esses terceiros.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Proteção da VamuveTV e outros:</strong>
                        {' '}
                        a VamuveTV e seus Prestadores de Serviços poderão divulgar ou, de outra forma, utilizar seus dados pessoais quando
                        a VamuveTV ou tais empresas tiverem motivos razoáveis para acreditar que tal divulgação é necessária para (a)
                        atender a alguma lei ou norma aplicável, processo legal ou solicitação governamental, (b) fazer cumprir os termos
                        de uso aplicáveis, incluindo a investigação de potenciais infrações destes, (c) detectar, prevenir ou endereçar
                        atividades ilegais ou suspeitas (inclusive fraude de pagamentos), problemas técnicos ou de segurança ou (d)
                        proteger-se contra infrações aos direitos, propriedade ou segurança da VamuveTV, de seus usuários ou do público,
                        conforme requerido ou permitido por lei.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Transferência de propriedade:</strong>
                        {' '}
                        em relação a qualquer reorganização, reestruturação, fusão ou venda, ou transferência de ativos, a VamuveTV
                        transferirá dados, incluindo dados pessoais, desde que a parte receptora se comprometa a respeitar seus dados
                        pessoais de maneira condizente com nossa Declaração de privacidade.
                      </li>
                    </ul>
                    No decorrer do compartilhamento de dados, sempre que transferirmos dados pessoais, nós nos
                    certificaremos de que os dados sejam transferidos segundo esta Declaração de privacidade e
                    conforme permitido pela legislação de proteção de dados aplicável. Ressaltamos que o compartilhamento
                    se dará por meio da adoção de medidas técnicas e organizacionais adequadas, visando a confidencialidade
                    e integridade das informações.
                    <br />
                    <br />
                    Você também poderá optar por divulgar seus dados das seguintes maneiras:
                    <br />
                    <br />
                    <ul>
                      <li>
                        determinadas seções do serviço VamuveTV poderão conter uma ferramenta que dá a você a opção de
                        compartilhar dados por email, mensagem de texto ou outros aplicativos de compartilhamento por
                        intermédio de softwares cliente e aplicativos instalados no seu aparelho;
                      </li>
                      <li>
                        plug-ins de redes sociais e tecnologias similares permitem o compartilhamento de dados; e
                      </li>
                      <li>
                        recursos dos jogos da VamuveTV, como opções para vários jogadores e rankings.
                      </li>
                    </ul>
                    Plug-ins e aplicativos de redes sociais são operados pelas próprias plataformas de redes sociais
                    e estão sujeitos aos seus respectivos termos de uso e políticas de privacidade. Da mesma forma,
                    alguns dos recursos dos jogos da VamuveTV mencionados acima (
                    <em>
                      como jogar com vários jogadores, rankings e opções para salvar jogos
                    </em>
                    ) podem exigir o uso de serviços de terceiros. Esse uso está
                    sujeito aos termos de uso e à política de privacidade de tais serviços de terceiros.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Acesso à conta e perfis</h3>
                  <span>
                    <ul>
                      <li>
                        <strong>Permitir que outras pessoas acessem a sua conta:</strong>
                        {' '}
                        e você compartilhar ou permitir que outras pessoas tenham acesso à sua conta, elas poderão ver os filmes
                        e séries a que você assistiu, as classificações, os dados da sua conta (
                        <em>
                          inclusive seu email, outros dados na seção “Perfil” no nosso site
                        </em>
                        ) e dados sobre os jogos (
                        <em>
                          como o nome que você usa nos jogos e o progresso no jogo salvo
                        </em>
                        ). Isso ocorre mesmo utilizando o recurso de perfis. No presente momento, a VamuveTV não
                        disponibiliza e nem tampouco autoriza a opção de usar o login integrado ou por pessoa diversa
                        do assinante, por questões de segurança tanto da VamuveTV como do próprio assinante. Portanto,
                        não é permitido ao assinante emprestar, ceder ou de qualquer forma tornar acessível sua senha
                        e/ou login para terceiros, estando sujeito ao cancelamento e/ou suspensão imediata da prestação
                        de serviços, no momento em que essa situação for identificada;
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Perfis:</strong>
                        {' '}
                        os perfis permitem que os usuários tenham uma experiência VamuveTV personalizada e distinta,
                        criada com base nos filmes, desenhos, séries e demais obras audiovisuais de seu interesse, cada
                        um com o seu próprio histórico de títulos assistidos. Os perfis estão à disposição de todos
                        aqueles que utilizarem sua conta da VamuveTV, portanto, qualquer pessoa com acesso à sua conta
                        VamuveTV poderá acessar, utilizar, editar e excluir perfis, razão pela qual reiteramos a proibição
                        ao assinante de emprestar, ceder ou de qualquer forma tornar acessível seu login e/ou senhas
                        pessoais para terceiros;
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Suas opções</h3>
                  <span>
                    <ul>
                      <li>
                        <strong>Emails de segurança:</strong>
                        {' '}
                        Caso você tenha esquecido sua senha pessoal, você poderá recuperá-la acessando a opção “esqueci a senha”
                        e fornecer o email que você utilizou ao cadastrar sua conta. Após realizar o procedimento de recuperação,
                        um email lhe será enviado com as explicações de como proceder para recuperá-la e você poderá ser
                        redirecionado para a seção disponível em nossa plataforma para que sua senha seja redefinida. A redefinição
                        de senha é um procedimento gratuito oferecido pela VamuveTV, portanto não lhe será cobrado taxa, ou
                        valores adicionais relativos à recuperação de sua senha. Para sua segurança, afim de evitar tentativa
                        de fraude praticada por terceiros ou roubo de suas senhas ou dados pessoais ou atitudes que comprometam
                        a integridade de sua conta, não acesse nenhum email diverso do da VamuveTV que contenham links que sejam
                        estranhos aos nossos serviços ou que lhe redirecione para endereços eletrônicos, websites, ou aplicativos que
                        não sejam os da VamuveTV, nem tampouco forneça dados bancários ou realize pagamentos supostamente relativos
                        ou relacionados à recuperação de senha simulando serem em nome da VamuveTV, pois podem se tratar de softwares
                        mal intencionados de terceiros utilizados para obter acesso indevido aos dados de sua conta.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Aviso de lembretes de vencimento:</strong>
                        {' '}
                        Durante a semana que anteceder ao vencimento da prestação dos serviços, a VamuveTV irá lhe encaminhar pelo
                        seu perfil pessoal, email ou ambos, um ou mais avisos lhe lembrando do vencimento para possibilitar a você
                        a opção de renovar seu plano de assinatura. Caberá à você decidir pela continuação ou não dos serviços prestados.
                        Caso você opte pela renovação e, portanto, pela continuação dos serviços, basta logar em sua conta, acessar
                        seu perfil, e antes de vencer a sua assinatura, efetuar o pagamento da fatura subsequente de acordo com o
                        plano escolhido. Caso opte por não continuar com os serviços, bastará que não efetue o pagamento da próxima
                        fatura. O não pagamento da fatura na data aprazada, incorrerá na interrupção dos serviços e no cancelamento
                        de sua assinatura. Fique ciente de que, mesmo que sua conta tenha sido cancelada, você poderá, a qualquer
                        tempo, renovar sua assinatura caso queira, bastando efetuar o procedimento feito anteriormente para criar
                        uma nova conta.
                      </li>
                      <li>
                        <strong>Mensagens de email e texto:</strong>
                        {' '}
                        A VAMUVETV poderá lhe proporcionar opções para o recebimento de determinados comunicados por email ou
                        mensagens de texto, relativas à outros produtos, serviços, newsletter oferecidos pela plataforma. Estas
                        informações, caso estejam disponíveis, poderão ser ativadas ou desativadas apenas dentro da opção
                        correspondente na seção “Perfil” relativa à sua conta em nosso site. Se tiver interesse em receber estes
                        comunicados bastará que marque as opções à eles correspondentes, bem como você poderá, à qualquer tempo,
                        interromper o recebimento destes comunicados bastando que desmarque as opções que tenham sido ativadas. Se
                        estas opções para ativação ou desativação ainda não estiverem disponíveis em seu perfil, isso significa que
                        esse tipo de serviço de envios de comunicados ainda não foi disponibilizado ao usuário. Para sua segurança,
                        afim de evitar tentativa de fraude praticada por terceiros ou roubo de suas senhas ou dados pessoais ou
                        atitudes que comprometam a integridade de sua conta, não passe sua senha ou login para terceiros, bem
                        como não acesse nenhum email diverso do da VamuveTV que contenham links que sejam estranhos aos nossos
                        serviços ou que lhe redirecione para endereços eletrônicos, websites, ou aplicativos que não sejam os da
                        VamuveTV, nem tampouco forneça dados bancários ou realize pagamentos supostamente relativos à quaisquer
                        serviços que você não tenha solicitado e/ou ativado na seção “Perfil” em sua conta, pois podem se tratar de
                        softwares mal intencionados de terceiros utilizados para obter acesso indevido aos dados de sua conta
                      </li>
                      <li>
                        <strong>Notificações push e WhatsApp:</strong>
                        {' '}
                        No presente momento, a VAMUVETV não possui serviços de envio de notificações via push, whatsapp
                        ou anúncios baseados em interesse. Quando estes serviços estiverem disponíveis, você poderá acessá-los
                        na seção “Perfil” em sua conta. Estes e outros serviços que venhamos à oferecer, poderão acarretar
                        atualizações nos termos de uso, política de privacidade ou outras documentações disponíveis em
                        nossa plataforma.
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Seus dados e direitos</h3>
                  <span>
                    Você pode solicitar confirmação de que processamos seus dados pessoais, bem como solicitar
                    acesso aos seus dados pessoais e corrigir ou atualizar dados pessoais desatualizados ou
                    incorretos que temos sobre você. Você também pode solicitar que a VamuveTV exclua seus dados pessoais.
                    <br />
                    <br />
                    Ao acessar a seção “Perfil” no nosso site, onde você pode ver e atualizar diversos dados sobre sua conta,
                    inclusive seus dados de contato, de pagamento e diversas outras relacionadas à sua conta (
                    <em>
                      como o conteúdo a que você assistiu e classificou, caso estas opções estejam disponíveis
                    </em>
                    ). Para acessar a seção “Perfil” você precisa estar registrado.
                    <br />
                    <br />
                    Você pode se opor ao uso de seus dados pessoais, solicitar que a VamuveTV restrinja o uso de
                    seus dados pessoais ou solicitar a portabilidade deles; se a VamuveTV coletou e processou seus
                    dados pessoais com o seu consentimento, você pode retirar esse consentimento a qualquer momento; a
                    retirada do seu consentimento não afeta a legalidade do uso que fizemos desses dados antes da retirada
                    do seu consentimento nem afeta o uso dos seus dados pessoais tratados com outra base legal além do
                    consentimento; você pode solicitar que a VamuveTV informe se usou seus dados pessoais de forma compartilhada
                    (consulte a seção “Divulgação de dados” acima); você tem o direito de questionar à Autoridade Nacional de
                    Proteção de Dados se não conseguir resolver qualquer preocupação com a VamuveTV.
                    <br />
                    <br />
                    Respondemos a todas as solicitações que recebemos de indivíduos que queiram exercer os seus direitos de proteção
                    de dados em conformidade com as respectivas leis de proteção de dados. Consulte também a seção “Suas opções”
                    desta Declaração de privacidade para ver quais são suas outras opções relativas aos seus dados pessoais.
                    <br />
                    <br />
                    A VamuveTV pode rejeitar solicitações que sejam desarrazoadas ou não exigidas por lei, como aquelas que sejam
                    extremamente impraticáveis, capazes de exigir um esforço técnico desproporcional ou que possa nos expor a riscos
                    operacionais, como fraude em relação ao período de utilização. A VamuveTV pode reter dados conforme exigência
                    ou permissão prevista em leis e regulamentos aplicáveis, inclusive para honrar suas escolhas, para fins de cobrança
                    ou registros e para atender às finalidades descritas nesta Declaração de privacidade. A VamuveTV toma medidas
                    razoáveis para destruir ou anonimizar dados pessoais de forma segura quando deixam de ser necessários.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Segurança</h3>
                  <span>
                    A VamuveTV emprega medidas administrativas, lógicas, gerenciais e físicas razoáveis para proteger
                    seus dados pessoais contra perdas, roubos e acesso, uso e alterações não autorizados. Essas
                    medidas são elaboradas para oferecer um nível de segurança adequado aos riscos de processamento
                    de seus dados pessoais.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Outros sites, plataformas e aplicativos</h3>
                  <span>
                    O serviço VamuveTV pode ser fornecido por plataformas de terceiros e/ou utiliza recursos (
                    <em>
                      como controles por voz
                    </em>
                    ) operados por plataformas de terceiros, bem como pode conter links para sites de terceiros, cujas
                    políticas relativas ao tratamento de dados podem diferir das nossas. Por exemplo, você pode acessar
                    o serviço VamuveTV por meio de plataformas como videogames, Smart TVs, celulares e decodificadores e
                    diversos aparelhos com conexão à Internet. Esses sites e plataformas têm políticas de privacidade e dados,
                    declarações de privacidade, termos e avisos de uso separados e independentes, e recomendamos que você os
                    leia atentamente. Além disso, você pode encontrar aplicativos de terceiros que interajam com o serviço VamuveTV.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Crianças</h3>
                  <span>
                    Você deve ter pelo menos 18 anos para assinar o serviço VamuveTV. Menores podem usar o serviço
                    somente com o envolvimento, a supervisão e a aprovação dos pais ou responsáveis.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Alterações na presente Declaração de privacidade</h3>
                  <span>
                    A VamuveTV poderá periodicamente alterar esta Declaração de privacidade para atender a mudanças na
                    legislação, exigências regulatórias ou operacionais. Comunicaremos eventuais alterações (inclusive a
                    data em que entrarão em vigor) conforme previsto por lei. O uso continuado por você do serviço VamuveTV
                    depois que tais alterações passarem a vigorar constituirá seu reconhecimento e (conforme o caso) a sua
                    aceitação delas. Caso não queira reconhecer ou aceitar nenhuma das atualizações desta Declaração de
                    privacidade, você poderá cancelar o serviço VamuveTV. Para saber quando essa Declaração de privacidade
                    foi atualizada pela última vez, consulte a seção “Última atualização”, abaixo.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Cookies e publicidade na Internet</h3>
                  <span>
                    A VamuveTV e os nossos Prestadores de Serviços contratados utilizam cookies e outras tecnologias (
                    <em>
                      tais como web beacons
                    </em>
                    ), assim como identificadores de aparelho redefiníveis, por diversos motivos. Nós queremos informá-lo do
                    nosso uso dessas tecnologias. Assim, esta seção explica quais tipos de tecnologias utilizamos, sua função
                    e suas opções em relação ao uso dessas tecnologias.
                    <br />
                    <br />
                    Cookies são pequenos arquivos de dados, armazenados normalmente no seu aparelho enquanto você navega e
                    utiliza sites e serviços online. Usamos outras tecnologias, como armazenamento de navegador e plug-ins (
                    <em>
                      por exemplo, HTML5, IndexedDB e WebSQL
                    </em>
                    ). Como os cookies, essas outras tecnologias podem armazenar pequenas quantidades de dados em seu aparelho.
                    Os web beacons (
                    <em>
                      também conhecidos como clear gifs ou pixel tags
                    </em>
                    ) normalmente funcionam em conjunto com os cookies. Em vários casos, rejeitar os cookies impedirá também
                    a eficácia dos web beacons associados a esses cookies.
                    <br />
                    <br />
                    Os cookies armazenam temporariamente algumas informações sobre o que está sendo buscado em nosso site.
                    Os cookies podem manter os registros de navegação e das suas preferências, salvando tudo para Você entrar
                    direto na próxima vez em que acessar. Os cookies podem ser gerenciados por Você a qualquer tempo, basta
                    limpar os dados da sua navegação quando terminar a visita ao nosso site.
                    <br />
                    <br />
                    Uma das formas de coletar informações de navegação é pelo uso de cookies. Uma vez habilitados, estes nos
                    ajudam a efetuar uma prestação de serviços cada vez mais personalizada, pensando sempre em apresentar os
                    melhores produtos e serviços de acordo com a sua necessidade.
                    <br />
                    <br />
                    Você pode não aceitar os cookies para utilizá-los, mas nestas situações a VamuveTV não pode garantir
                    o correto funcionamento desses sites. Os cookies podem ser aceitos, excluídos ou rejeitados por meio de
                    ferramentas de gerenciamento do próprio navegador usado por você ou por meio da configuração do seu navegador
                    no modo anônimo. Deste modo, o seu navegador não guardará o seu histórico de navegação, cookies dos sites
                    e informações fornecidas em formulários.
                    <br />
                    <br />
                    Caso deseje saber como gerenciar cookies em navegadores populares, acesse os seguintes links:
                    <br />
                    <ul>
                      <li>
                        <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">Google Chrome</a>
                      </li>
                      <li>
                        <a href="https://support.microsoft.com/pt-br/windows/microsoft-edge-dados-de-navega%C3%A7%C3%A3o-e-privacidade-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                          Microsoft Edge
                        </a>
                      </li>
                      <li>
                        <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                          Firefox
                        </a>
                      </li>
                      <li>
                        <a href="https://help.opera.com/en/latest/web-preferences/">
                          Opera
                        </a>
                      </li>
                      <li>
                        <a href="https://support.apple.com/en-gb/safari">
                          Safari
                        </a>
                      </li>
                    </ul>
                    <br />
                    A VAMUVETV utiliza o Google Analytics e o pixel do Facebook para a coleta e processamento de dados,
                    com as quais o usuário ao efetivar sua assinatura, plenamente concorda ao aceitar a presente Política
                    de Privacidade.
                    <br />
                    <br />
                    Para mais informações, você poderá consultar as políticas do Google e Facebook disponíveis em:
                    <br />
                    <ul>
                      <li>
                        <a href="https://policies.google.com/technologies/partner-sites?hl=pt-BR">
                          Google Privacy
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/policy.php">
                          Facebook Privacy
                        </a>
                      </li>
                    </ul>
                    <br />
                    Quaisquer usos feitos pelo Google ou seus parceiros dos Dados do usuário coletados por meio dessas
                    ferramentas serão de responsabilidade única e exclusiva do Google e Facebook, sendo a VAMUVETV isenta
                    de quaisquer responsabilidades resultantes de tal uso.
                    <br />
                    <br />
                    A VamuveTV aplica medidas contratuais e técnicas projetadas para impedir que os Parceiros de Publicidade
                    acessem dados sobre títulos específicos selecionados, URLs acessados ou séries assistidas por você em
                    nosso serviço. Não compartilhamos dados sobre títulos selecionados, séries ou demais obras audiovisuais
                    assistidas em nosso serviço.
                    <br />
                    <br />
                    <strong>Última atualização:</strong>
                    {' '}
                    20 de novembro de 2021
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
