import axios from 'axios';
import { getUUID } from '../Services/token';

// const baseURL = 'http://www.vamuvetv.com/vamuvetv-site-api/'
const adminApiBaseURL = 'https://www.vamuvetv.com/vamuvetv-adm-api/';
const siteApiBaseURL = 'https://www.vamuvetv.com/vamuvetv-site-api/';

const adminApi = axios.create({
  baseURL: adminApiBaseURL,
});

const siteApi = axios.create({
  baseURL: siteApiBaseURL,
});

const interceptor = async (config) => {
  const token = getUUID();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

siteApi.interceptors.request.use(interceptor);
adminApi.interceptors.request.use(interceptor);

export {
  adminApi,
  siteApi,
};

export default siteApi;
