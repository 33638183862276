import React, { Component } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Tidings from '../../Components/News';
import Axios from '../../Axios';
import Loading from '../../Components/Loading';

export default class News extends Component {
  state = {
    news: null,
    loading: false,
  };

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    this.setState({ loading: true });
    Axios.get('https://www.vamuvetv.com/vamuvetv-adm-api/noticias')
      .then(resp => {
        const data = resp.data;
        if (data.status) {
          this.setState({
            news: data.response.message,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  convertDate = date => {
    const brokenString = date.split(' ');

    const newDate = new Date(brokenString[0])
      .toISOString()
      .substr(0, 10)
      .split('-')
      .reverse()
      .join('/');

    return `${newDate} às ${brokenString[1]}`;
  };

  convertSinopsis(textEncrip) {
    const htmlToText = require('html-to-text');

    const text = htmlToText.fromString(textEncrip, {
      wordwrap: 130,
    });
    return text;
  }

  render() {
    return (
      <>
        <Header />
        <div
          className="container section single-movie"
          style={{ padding: '100px' }}
        >
          <div className="row">
            <div className="col-sm-12">
              <h2>Notícias</h2>
              {this.state.news ? (
                this.state.news.map(article => (
                  <Tidings
                    id={article.id}
                    cover={article.image}
                    title={article.title}
                    date={this.convertDate(article.post_date)}
                    text={article.text}
                  />
                ))
              ) : (
                <>
                  <h1>Sem noticias aqui...</h1>
                </>
              )}
            </div>
          </div>
        </div>
        <Loading
          open={this.state.loading}
          text={'Estamos buscando novas notícias, aguarde...'}
          theme={'white'}
        />
        <Footer />
      </>
    );
  }
}
