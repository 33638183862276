import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function About() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div>
        <div className="">
          <div id="app-show-movie" className="wrapper">
            <div className="container section single-movie">
              <h2>Quem somos</h2>
              <div className="faq-item-wrapper">
                <div className="faq-item">
                  <h3>O que é a VamuveTV?</h3>
                  <span>
                    A VamuveTV é um serviço inovador na área de entretenimento, que envolve a locação de
                    nossa plataforma para o acesso à um imenso acervo contendo milhares de informações
                    sobre filmes, atores, notícias e obras audiovisuais em geral, bem como acesso à
                    visualização de filmes online permitindo aos clientes assistir a uma ampla variedade
                    de filmes, desenhos, documentários, programas de televisão e séries clássicas da
                    TV de forma simples e sem sair de casa.
                    <br />
                    <br />
                    Do material constante em nosso acervo, você pode não apenas realizar pesquisas sobre
                    seu filme ou atores preferidos, como também poderá assistir a quantos filmes, desenhos,
                    séries e programas quiser, quando quiser, sem comerciais durante a execução destes – tudo
                    por um preço mensal bem acessível. Aqui você sempre encontra novidades. A cada semana,
                    adicionamos novas séries, filmes e informações sobre material audiovisual constante
                    em nosso acervo.
                    <br />
                    <br />
                    Um dos nossos objetivos é nos tornarmos o principal serviço de entretenimento, locação,
                    distribuição e produção de filmes clássicos e
                    {' '}
                    <em>vintage</em>
                    {' '}
                    no Brasil, principalmente aqueles
                    já decaídos ao domínio público, bem como criação e apresentação de programas de televisão
                    diferenciados, busca de novos talentos e fomento ao resgate cultural e artístico, buscando
                    propiciar aos seus assinantes uma experiência de completa e total imersão na área de
                    entretenimento, aí incluindo tanto os filmes clássicos ou vintage já veiculados no cinema
                    e televisão brasileiros como aqueles que por nós venham a ser produzidos.
                    <br />
                    <br />
                    Para tanto, estaremos buscando dispor ao longo do tempo, um acervo imenso de filmes, desenhos
                    e seriados, (
                    <em>e, sempre que possível, com ênfase à dublagem clássica</em>
                    ) de forma que você possa realizar uma verdadeira viagem ao passado bem como estaremos aos
                    poucos dispondo programas próprios para melhor lhes entreter.
                    <br />
                    <br />
                    Dispomos também para você, uma gama de informações sobre milhares de filmes e seus respectivos
                    elencos para você poder pesquisar sobre sua obra preferida, informações estas que formam nossa
                    filmoteca VamuveTV. A filmoteca é um espaço digital por onde você pode consultar informações
                    sobre seu filme preferido. Atualmente criamos um cadastro de algo em torno de 200.000 filmes
                    e aproximadamente 100.000 membros de seus respectivos elencos para você poder consultar à vontade.
                    As informações constantes em nossa filmoteca contém aquelas referentes à filmes nacionais e
                    internacionais desde o séc XIX até o início de 1950 e está em constante atualização. Como se
                    trata de uma quantidade enorme de informação, estamos aos poucos atualizando os dados de cada obra
                    e de seu elenco, sendo para tanto necessário pesquisa fílmica detalhada que realizamos em diferentes
                    fontes de referências, sejam públicas ou privadas, como notícias de jornais e revistas da época,
                    sites especializados em material de cinema e TV, sites ou instituições especializadas em obras
                    audiovisuais em domínio público, informações fornecidas por colegas, fãs, colecionadores, cineastas,
                    entusiastas, estudiosos ou pesquisadores de cinema etc. Aos poucos, estas informações são atualizadas,
                    organizadas e contextualizadas para a época da criação de cada obra, para que você possa ter informações
                    mais detalhadas sobre determinada obra ou membro de elenco que está procurando.
                    <br />
                    <br />
                    Nossa plataforma está em constante evolução e desenvolvimento e, para tanto, estamos buscando realizar
                    parcerias com o setor público e privado, distribuidoras, produtoras e estúdios de forma a lhes garantir
                    melhor acesso à cultura, proporcionando-lhes maior lazer e entretenimento.
                    <br />
                    <br />
                    O assinante VamuveTV pode assistir, no âmbito doméstico e privado, a quantos filmes e séries quiser, nos
                    horários de sua escolha, em praticamente qualquer tela com conexão à Internet.
                    <br />
                    <br />
                    A assinatura engloba não apenas a locação para o acesso à todo o conteúdo do acervo existente no site,
                    como também inclui o acesso às imagens, informação de filmes, atores e demais membros dos elencos, artigos
                    e notícias em geral sobre cinema e televisão e acesso ao nosso material audiovisual, na forma de
                    assinatura mensal.
                    <br />
                    <br />
                    O mercado de vídeo-locação no Brasil vem sofrendo um violento impacto tanto pela modernização da tecnologia
                    que, ao longo dos anos, veio à substituir a locação convencional de filmes, como pelo aumento da prática
                    de se redublarem títulos épicos, o que resulta no desinteresse das pessoas em assistir, locar ou adquirir
                    títulos que não contenham a dublagem clássica pois perdeu-se a identidade auditiva inerentes aos filmes
                    que assistíamos quando mais jovens. Por tal razão, a empresa foi criada e está sendo desenvolvida para
                    suprir essa carência no mercado e, ao mesmo tempo, acompanhar o desenvolvimento tecnológico destinado ao
                    setor, inovando o mercado de locação doméstica de filmes, sobretudo aqueles inerentes ao domínio público.
                    <br />
                    <br />
                    Nossa meta é não somente a locação do acesso à filmes e obras clássicas e vintage, mas também a produção
                    de programas, seriados, desenhos, apresentação de novos talentos, como forma de preservação de
                    acervo audiovisual, proporcionando uma melhor forma de entretenimento no mercado cinematográfico,
                    televisivo, educativo, cultural e de entretenimento em geral, e buscando fomentar o aumento do
                    comércio regular de filmes clássicos mediante parcerias com distribuidores de todo o Brasil, para
                    que fãs e colecionadores possam, não apenas rever em nossa plataforma estas peças maravilhosas que
                    marcaram suas infâncias como também, possam adquiri-los em sua coleção diretamente de distribuidoras,
                    estúdios ou lojas especializadas no setor e que sejam parceiras da VamuveTV (
                    <em>Tanto licenciados como licenciantes</em>
                    ).
                    <br />
                    <br />
                    Amor, criatividade e, sobretudo inovação são as chaves para o sucesso no mercado de entretenimento.
                    <br />
                    <br />
                    Dispomos de parcerias com estúdios gráficos, produtoras, agentes publicitários, cinéfilos, colecionadores,
                    dispostos à transformar nossos sonhos e lembranças de infância em pura realidade.
                    <br />
                    <br />
                    Para tanto, a VamuveTV, criou e registrou uma série de personagens intitulados “TVNautas” dispostos
                    no nosso site e plataformas online, para que o usuário possa interagir, se divertir e se engajar numa
                    verdadeira aventura rumo ao passado. VamuveTV e TVnautas são marcas registradas de VamuveTV Participações e Empreendimentos.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Nosso objetivo</h3>
                  <span>
                    Temos por missão o resgate, a perpetuação, manutenção de obras audiovisuais veiculadas nas
                    telas e cinemas brasileiros mantendo-se intacta a dublagem clássica veiculada originalmente,
                    além de fornecermos um rico acervo contendo informações sobre filmes, desenhos seriados, os
                    respectivos elencos, além de área de notícias sobre TV, cinema e entretenimento em geral e a
                    apresentação de programas próprios à serem realizados por nossa emissora online.
                    <br />
                    <br />
                    Esse trabalho hercúleo de recuperação é feito de forma à se garantir a perpetuação da cultura
                    em nosso País, proporcionando à nossos assinantes a eternização da magia da telinha representada
                    como nos tempos áureos da televisão brasileira.
                    <br />
                    <br />
                    Somos contrários à toda forma de redublagem que possa implicar, direta ou indiretamente na destruição
                    do acervo audiovisual, salvo naquelas hipóteses de obras raras, cuja dublagem clássica tenha se
                    perdido ao longo do tempo. Por tal razão estamos enveredando esforços para garantir a preservação
                    destas obras de forma que você possa ter a verdadeira experiência da magia do cinema tal e qual era
                    visto nas telinhas da TV ou cinemas nos anos 30, 40, 50, 60, 70 e 80.
                    <br />
                    <br />
                    A VamuveTV é uma empresa de produção, distribuição, preservação, locação e emissão de vídeos e dados
                    digitais audio-visuais relacionados à TV e cinema.
                    <br />
                    <br />
                    A empresa entende que, o desenvolvimento e acesso à cultura deva ser preservado para a posteridade,
                    razão pela qual, estamos enveredando esforços para realizar parcerias com empresas privadas, distribuidoras,
                    estúdios de cinema e televisão, e órgãos públicos como a Biblioteca Nacional, Arquivo nacional e demais
                    órgãos de preservação de material audiovisual do País.
                    <br />
                    <br />
                    Da mesma forma, somos contra toda forma de lobismo, destruição ou ocultação de obras audiovisuais, que
                    acabem visando à apenas servir à interesses privados ou usarem de subterfúgios para perpetuar
                    indefinidamente obras que já caíram em domínio público, em flagrante violação a legislação autoral
                    brasileira, e que acabem por fim, destruindo a cultura de nosso País já tão carente de projetos e
                    ideias que visem a perpetuação da cultura e a salvaguarda de nossa memória afetiva ligada diretamente
                    à material audiovisual que assistíamos em nossas infâncias. Entendemos que o interesse privado, em
                    nenhuma circunstância, está acima do direito de acesso à cultura, lazer e entretenimento, direitos
                    coletivos estes assegurados constitucionalmente, razão pela qual, nosso acervo é composto basicamente
                    de obras que já caíram em domínio público. Embora nosso foco sejam obras decaídas em domínio público,
                    eventualmente poderemos realizar contratos de licenciamento para obras que ainda estejam protegidas,
                    como por exemplo, filmes ou series das décadas de 60, 70 e 80 ou programas de televisão ou de redes
                    sociais licenciados para a VamuveTV mediante parcerias com estúdios, distribuidores ou produtoras regularizadas.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
