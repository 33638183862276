import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Axios from '../../Axios';
import Loading from '../../Components/Loading';

export default class NewsDetail extends React.Component {
  state = {
    news: null,
    loading: false,
  };

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    this.setState({ loading: true });
    Axios.get(`https://www.vamuvetv.com/vamuvetv-adm-api/noticias/${this.props.match.params.id}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            news: data.response.message,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  convertDate = (date) => {
    const brokenString = date.split(' ');

    const newDate = new Date(brokenString[0])
      .toISOString()
      .substr(0, 10)
      .split('-')
      .reverse()
      .join('/');

    return `${newDate} às ${brokenString[1]}`;
  };

  convertSinopsis(textEncrip) {
    const htmlToText = require('html-to-text');

    const text = htmlToText.fromString(textEncrip, {
      wordwrap: 130,
    });
    return text;
  }

  render() {
    return (
      <>
        <Header />
        <div
          className="container section single-movie"
          style={{ padding: '100px' }}
        >
          <div className="row">
            <div className="col-sm-12">
              <h2>Notícias</h2>
              {this.state.news ? (
                <div className="row" style={{ marginTop: '1rem' }}>
                  <div className="col-sm-12" style={{ borderBottom: '1px solid #ccc' }}>
                    <h3 className="no-underline">{this.state.news.title}</h3>
                    <h4 className="no-underline">{this.state.news.date}</h4>
                    <div dangerouslySetInnerHTML={{ __html: this.state.news.text }} />
                  </div>
                </div>
              ) : (
                <>
                  <h1>Sem noticias aqui...</h1>
                </>
              )}
              <Link to="/noticias" className="titleNews">VOLTAR</Link>
            </div>
          </div>
        </div>
        <Loading
          open={this.state.loading}
          text="Estamos buscando novas notícias, aguarde..."
          theme="white"
        />
        <Footer />
      </>
    );
  }
}
