import React from 'react'
import Axios from '../../Axios'

export default function Blog ({ avatar, date, name, comment, id_mv }) {
  React.useEffect(() => {
    getComments()
  })

  function getComments () {
    Axios.post('/blog/comentarios/filme', { id_mv: id_mv })
      .then(resp => {
        console.log(resp)
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <div className='container section single-movie'>
        <div className='row'>
          <div className='col-sm-7'>
            <h2>Comentários</h2>

            <div className='comments'>
              <div className='row'>
                <div className='col-sm-2'>
                  <img src={avatar} alt={avatar} />
                </div>
                <div className='col-sm-9'>
                  <span className='date'>{date}</span>
                  <h4 className='no-underline'>{name}</h4>
                  <p>{comment}</p>
                </div>
              </div>
            </div>

            <p>Sem nenhum comentário sobre o filme</p>
            <p>Seja o primeiro e comente ao lado o que achou!</p>
          </div>
          <div className='col-sm-4 col-sm-push-1'>
            <h2>Deixe seu comentário</h2>
            <form>
              <div className='form-group'>
                <label>Nome *</label>
                <input type='text' />
              </div>
              <div className='form-group'>
                <label>E-mail *</label>
                <input type='email' />
              </div>
              <div className='form-group'>
                <label>Comentário *</label>
                <textarea rows='5' />
              </div>
              <div className='form-group right-align'>
                <button className='btn btn-ghost'>Postar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
