import React from 'react'
import Blog from '../Blog'
import Axios from '../../Axios'
import Header from '../Header'
import Footer from '../Footer'
import { isAuthenticated, getAccountType } from '../../Services/token'
import { HashRouter, Link } from 'react-router-dom'
import Loading from '../Loading'

export default class Serie extends React.Component {
  state = {
    serie: null,
    loading: false
  }

  componentDidMount () {
    this.setState({ loading: true })
    Axios.get(`/serie/${this.props.match.params.id}`)
      .then(resp => {
        const data = resp.data
        if (data.status) {
          this.setState({
            serie: data.response.message.serie,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        alert(`Erro de conexão : ${error} CINCO`);
      })
  }

  convertSinopsis () {
    const htmlToText = require('html-to-text')

    const text = htmlToText.fromString(this.state.serie.sr_sinopse, {
      wordwrap: 130
    })
    return text
  }

  splitDirectors = directors => {
    const names = directors.split(', ')
    return names.map((name, i) => (
      <HashRouter>
        <Link to={`/diretor/${name.toLowerCase()}`}>
          {directors.length === 1
            ? name
            : i + 1 === names.length
              ? name
              : name + ', '}
        </Link>
      </HashRouter>
    ))
  }

  convertDate = () => {
    return new Date(this.state.serie.launch_date)
      .toISOString()
      .substr(0, 10)
      .split('-')
      .reverse()
      .join('/')
  }

  makeGalleryPictures = () => {
    const pictures = this.state.serie.sr_gallery.split(', ')
    return pictures.map((picture, key) => (
      <div className='movie-slide'>
        <img
          src={picture}
          alt={`banner ${key}`}
          style={{ maxHeight: '150px' }}
        />
      </div>
    ))
  }

  buttonsType = () => {
    return (
      <>
        <HashRouter>
          <Link
            to={`${
              isAuthenticated()
                ? getAccountType() === 'free'
                  ? `/assinar`
                  : `/reproduzir/serie/${this.state.serie.id_sr}`
                : `/login`
            }`}
            className='btn btn-default'
          >
            <i className='material-icons'>play_arrow</i>
            <span>Assista Agora</span>
          </Link>
        </HashRouter>

        <HashRouter>
          <Link
            to={`${
              isAuthenticated()
                ? getAccountType() === 'free'
                  ? `/alugar/serie/${this.state.serie.id_sr}`
                  : `/reproduzir/serie/${this.state.serie.id_sr}`
                : `/login`
            }`}
            className='btn btn-default'
            style={{ margin: '1rem' }}
          >
            <i className='material-icons'>play_arrow</i>
            <span>Alugue agora</span>
          </Link>
        </HashRouter>
      </>
    )
  }

  render () {
    return (
      <>
        <Header />
        {this.state.serie && (
          <div className=''>
            <div id='app-show-serie' className='wrapper'>
              <div id='hero' className='carousel slide carousel-fade'>
                <div className='carousel-inner'>
                  <div
                    className='item active'
                    style={{
                      backgroundImage: `url(${this.state.serie.sr_banner})`
                    }}
                  >
                    <div className='container'>
                      <div
                        className='row blurb scrollme animateme '
                        data-when='exit'
                        data-from='0'
                        data-to='1'
                        data-opacity='0'
                        data-translatey='100'
                      >
                        <div
                          className='col-md-12 black-layer '
                          mouseover='showPlayer'
                          mouseout='hidePlayer'
                          data-when='exit'
                          data-from='0'
                          data-to='1'
                          data-opacity='0'
                          data-translatey='100'
                        >
                          <h1>{this.state.serie.sr_title}</h1>
                          <transition name='bounce'>
                            {this.buttonsType()}
                          </transition>
                          <span className='title' />
                          <h1 className='shadow-text' />
                          <p className='shadow-text' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container section single-serie'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>Informações da série</h2>
                    <div className='row'>
                      <div
                        className='col-sm-5'
                        style={{ justifyContent: 'space-around' }}
                      >
                        <img
                          src={this.state.serie.sr_poster}
                          alt=''
                          className='poster'
                        />
                        <div
                          className='buttons'
                          style={{
                            display: 'flex',
                              flexDirection: 'row',
                              flexWrap : 'wrap',
                              justifyContent: 'center'
                          }}
                        >
                          <span
                            className={`certificate ${
                              this.state.serie.sr_ranking === 'Livre'
                                ? `certificate-0`
                                : `certificate-${parseInt(
                                  this.state.serie.sr_ranking
                                )}`
                            }
                                )}`}
                            title='Classificação'
                          >
                            {this.state.serie.sr_ranking}
                          </span>
                        </div>
                        {this.buttonsType()}
                      </div>
                      <div className='col-lg-7' style={{ overflow: 'hidden' }}>
                        <h3 className='no-underline'>
                          {this.state.serie.sr_title}
                        </h3>
                        <text>{this.convertSinopsis()}</text>
                        <ul className='movie-info'>
                          {/**   <li>
                            <i>Elenco</i>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Personagem</th>
                                  <th>Ator</th>
                                  <th>Papel</th>
                                  <th>Dublador</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>
                                    <a href="/"></a>
                                  </td>
                                  <td></td>
                                  <td>
                                    <a href="/"></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li> */}
                          <p>Sem Elenco cadastrado.</p>
                          <li>
                            <i>País de origem:</i>
                            {this.state.serie.sr_country}
                          </li>
                          <li>
                            <i>Diretor:</i>
                            {this.splitDirectors(this.state.serie.sr_director)}
                          </li>
                          <li>
                            <i>Lançamento:</i>
                            {this.convertDate()}
                          </li>
                          <li>
                            <i>Temporadas:</i>
                            {this.state.serie.total_seasons}
                          </li>
                          <li>
                            <i>Série IMDB: </i>
                            <a href={this.state.serie.serie_imdb}>
                              {this.state.serie.serie_imdb}
                            </a>
                          </li>
                          <li>
                            <i>Idioma(s):</i>
                            {this.state.serie.sr_languages}
                          </li>
                          <li>
                            <i>Categoria(s):</i>
                            {this.state.serie.sr_category}
                          </li>
                        </ul>
                        <p>Galeria de Fotos</p>
                        {this.state.serie.sr_gallery ? (
                          <div className='slick-carousel gallery'>
                            {this.makeGalleryPictures()}
                          </div>
                        ) : (
                          <div className='alert alert-warning' role='alert'>
                            <p>Galeria de fotos vazia.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {() => isAuthenticated() && <Blog />}
            </div>
          </div>
        )}
        <Footer />
        <Loading
          open={this.state.loading}
          text={'Estamos preparando a série, aguarde...'}
          theme={'white'}
        />
      </>
    )
  }
}
