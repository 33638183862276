import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Axios from '../../Axios'
import { isAuthenticated, getAccountType } from '../../Services/token'
import { Link } from 'react-router-dom'
import Loading from '../../Components/Loading'

export default function Programation () {
  const [programation, setProgramation] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getProgramation()
  }, [])

  function getProgramation () {
    setLoading(true)
    Axios.get(
      `/programacao/${new Date()
        .toLocaleString('pt-BR')
        .substr(0, 10)
        .split('/')
        .reverse()
        .join('-')}`
    )
      .then(resp => {
        const data = resp.data
        if (data.status) {
          setProgramation(data.response.message)
        }
        setLoading(false)
      })
      .catch(error => {
        console.error(`Connect Error  : ${error}`)
      })
  }

  function convertHour (hour) {
    const broken = hour.split(':')
    return broken[0] + ':' + broken[1]
  }

  function calculateDuration (start, minutes) {
    const calc = (minutes / 60).toFixed(1)
    const splitCalc = calc.split('.')
    const splitStart = start.split(':')
    const calcHour = parseInt(splitStart[0]) + parseInt(splitCalc[0])
    const calcMinutes = parseInt(splitStart[1]) + parseInt(splitCalc[1])

    const test = new Date()
          test.setHours(splitStart[0])
          test.setMinutes(splitStart[1] + minutes)
 
    return test.getHours() + ':' + test.getMinutes()
  }

  return (
    <>
      <Header />
      <div className=''>
        <div id='app-show-schedules' className='wrapper'>
          <div
            className='container section single-movie'
            style={{ paddingTop: '150px' }}
          >
            <div className='row'>
              <div className='col-sm-12'>
                <h2>Programação de filmes</h2>

                <div className='row'>
                  {programation ? (
                    programation.map(title => (
                      <div className='col-sm-5'>
                        <img src={title.mv_banner} alt='' className='poster' />
                        <div className='buttons'>
                          <span
                            className='certificate certificate-0'
                            title='classNameificação'
                          >
                            Livre
                          </span>
                          {isAuthenticated() ? (
                            getAccountType() === 'Member' ? (
                              <Link
                                className='venobox btn btn-default'
                                to='/streaming'
                              >
                                <i className='material-icons'>play_arrow</i>
                                <span>Assistir</span>
                              </Link>
                            ) : (
                              <Link
                                className='venobox btn btn-default'
                                to='/assinar'
                              >
                                <i className='material-icons'>play_arrow</i>
                                <span>Assistir</span>
                              </Link>
                            )
                          ) : (
                            <Link
                              className='venobox btn btn-default'
                              to='/login'
                            >
                              <i className='material-icons'>play_arrow</i>
                              <span>Assistir</span>
                            </Link>
                          )}
                        </div>
                        <br />

                        <div className='col-sm-7'>
                          <h3 className='no-underline'>{title.mv_title}</h3>
                          <h4 className='no-underline'>
                            Começa: {convertHour(title.hour_start)}
                          </h4>
                          <h4 className='no-underline'>
                            Termina:
                            {calculateDuration(
                              title.hour_start,
                              title.mv_duration
                            )}
                          </h4>
                          <h4 className='no-underline'>
                            Duracao (minutos): {`${title.mv_duration} min`}
                          </h4>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='alert alert-warning' role='alert'>
                      <p>
                        Ainda não temos nenhum filme programado para hoje, fique
                        de olho em nossas novidades. Você também pode alugar e
                        assistir filmes quando quiser em nossa plataforma.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading
        open={loading}
        text={'Estamos buscando a programação de hoje, aguarde...'}
        theme={'white'}
      />
      <Footer />
    </>
  )
}
