import React from 'react';
// import './styles.css';

export default function PictureModal({ open, gallery }) {
  const [change, setChange] = React.useState(open);

  function makeGalleryPictures() {
    if (gallery) {
      const pictures = gallery.split(', ');
      return pictures.map((picture, key) => (
        <div className="movie-slide">
          <img
            src={picture}
            alt={`banner ${key}`}
            style={{ maxHeight: '150px' }}
          />
        </div>
      ));
    }
  }

  return (
    <div
      className={`modal ${change ? 'show' : 'fade'}`}
      id="pictureModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="pictureModal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'inline',
              justifyContent: `space-between`,
            }}
          >
            <button
              type="button"
              style={{
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
              }}
              onClick={() => setChange(false)}
            >
              &times;
            </button>
          </div>
          <h2>Galeria de imagens</h2>
          <div className="slick-carousel gallery">
            {makeGalleryPictures()}
          </div>
        </div>
      </div>
    </div>
  );
}
