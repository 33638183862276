import React from 'react'

export default function Purchase ({ type, value, duration, plan, access }) {
  return (
    <>
      <div
        className='row col-md-12 purchase-info'
        style={{ margin: '50px auto;' }}
      >
        <div className='col-sm-12'>
          <h2>Dados da compra</h2>
          <table className='table table-hover table-striped'>
            <thead>
              <tr>
                <th>Tipo da Compra</th>
                <th>Valor da Compra</th>
                <th>Duração da assinatura</th>
                <th>Plano</th>
                <th>Acesso</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{type}</td>
                <td>R$ {value}</td>
                <td>{duration}</td>
                <td>{plan}</td>
                <td>{access}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
