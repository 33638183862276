import React, { Component } from 'react';
import { HashRouter, Link } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Axios from '../../Axios';
import { isAuthenticated, getAccountType } from '../../Services/token';
import Loading from '../../Components/Loading';

import { ReactComponent as Arrow } from '../../Assets/Icons/chevron.svg';
// import './styles.css'

const categoryes = [
  'animacao',
  'romance',
  'ficcao-cientifica',
  'drama',
  'comedia',
  'aventura',
  'suspense',
  'terror',
  'western',
];

const categoryesEng = [
  'animation',
  'love',
  'science',
  'drama',
  'comedy',
  'adventure',
  'survival',
  'scare',
  'western',
];

export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: null,
      inSoon: null,
      categories: [],
      loading: false,
      loadingMovies: false,
    };
  }

  componentDidMount() {
    const searchTerm = new URLSearchParams(window.location?.search).get('q');
    this.setState({search: searchTerm});
    this.getSlideTitles();
    // for (let i = 0; i < categoryes.length; i++) {
    //   this.getTitlesByCategory(categoryes[i]);
    // }
    this.loadMoviesByCategories(searchTerm);
    this.getCommingSoon();

    // const flavoursContainer = document.getElementById("row")
    // const flavoursScrollWidth = flavoursContainer.scrollWidth

    // window.addEventListener("load", () => {
    //    setInterval(() => {
    //     if (flavoursContainer.scrollLeft !== flavoursScrollWidth) {
    //       flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 5, 0);
    //     }
    //   }, 15);
    // })
  }

  getSlideTitles = () => {
    this.setState({ loading: true });
    Axios.get('/slide')
      .then((resp) => {
        const { data } = resp;

        if (data.status) {
          // const filtered = resp.data.response.message.map((x,index) => {
          //   if(index % 2 === 0) x.banner = "  https://www.correiodopovo.com.br/image/policy:1.401316:1582493618/.jpg?f=default&$p$f=53ee086"

          //   return x
          // })
          // this.setState({
          //   chosenBanner:resp.data.response.message[0],
          //   slides: filtered,
          //   loading: false
          // })

          this.setState({
            slides: resp.data.response.message,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        alert(`Erro de comunicacão com nossos servidores : ${error} SEIS`);
      });
  }

  getTitlesByCategory = (category, filter) => {
    this.setState({ loading: true });
    const filterTitles = [];

    Axios.get(`/movie/getAllCategoryMovies/${category}/AVAILABLE`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          for (let i = 0; i < 9; i++) {
            if (category === categoryes[i]) {
              if (filter) {
                if (filter === '2') {
                  data.response.message.forEach((title) => {
                    if (title.movie_url) {
                      filterTitles.push(title);
                    }
                  });
                  this.setState({
                    [categoryesEng[i]]: filterTitles || null,
                  });
                } else if (filter === '3') {
                  data.response.message.forEach((title) => {
                    if (!title.movie_url) {
                      filterTitles.push(title);
                    }
                  });
                  this.setState({
                    [categoryesEng[i]]: filterTitles.length > 0 ? filterTitles : null,
                  });
                }
              } else {
                this.setState({
                  [categoryesEng[i]]: data.response.message,
                });
              }
            }
          }
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        alert(`Erro de conexão : ${error} SETE`);
      });
  }

  loadMoviesByCategories = async (term) => {
    this.setState({ loadingMovies: true, categories: [] });
    const { data } = await Axios.get(`/filmes-por-categorias${term ? `?search=${term}` : ''}`);
    this.setState({
      loadingMovies: false,
      categories: data.response.message,
    });
  };

  searchMovie = (e) => {
    e.preventDefault();
    this.loadMoviesByCategories(this.state.searchInput);
  }

  getCommingSoon = () => {
    Axios.get('/slide/getCommingSoon')
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            inSoon: data.response.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        alert(`Erro de conexão : ${error} NOVE`);
      });
  }

  convertTitle = (title) => {
    if (title) {
      const brokenTitle = title.split(' ');
      return brokenTitle.join('-');
    }
    return '';
  }

  scrollSlider = (containerId, orientation) => {
    const found = document.getElementById(containerId);

    const widthToScroll = window.innerWidth < 768 ? (window.innerWidth * 0.9) : 500;

    if (found) {
      found.scroll({
        top: 0,
        left: orientation === 'FORWARD' ? found.scrollLeft + widthToScroll : found.scrollLeft - widthToScroll,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      categories,
      inSoon,
      loading,
      slides,
    } = this.state;
    return (
      <>
        <Header />
        <div className="">
          <div className="wrapper">
            <div
              style={{
                backgroundColor: '#000000',
                width: '100vw',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 50,
              }}
            >
              <button
                type="submit"
                onClick={() => this.scrollSlider('row', 'BACKWARD')}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  border: 0,
                }}
              >
                <Arrow style={{ height: 30, width: 30, transform: 'rotate(180deg)' }} />
              </button>
              <div id="row" style={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
                <div id="row_width" className="row__inner">
                  {!!slides && slides.map((slide, i) => (
                    <div className="tile" key={slide.id}>
                      <div className="tile__media">
                        <img className="tile__img" src={slide.banner} alt="" />
                      </div>
                      <div className="tile__details">
                        <div className="movie-categories-wrapper">
                          {
                            slide.category.split(',').map((x, index) => (
                              <span className="movie-categories" key={x}>
                                {x}
                                {index < slide.category.split(',').length - 1 ? ',' : ''}
                              </span>
                            ))
                          }

                        </div>
                        <span className="movie-categories-title">{slide.title}</span>
                        <div
                          className="movie-categories-sinopse-wrapper"
                          dangerouslySetInnerHTML={{ __html: slide.sinopse }}
                        />
                        <div className="buttons" style={{ marginTop: 30 }}>
                          <span
                            className={`certificate ${
                              slide.ranking === 'Livre'
                                ? 'certificate-0'
                                : `certificate-${parseInt(slide.ranking, 10)}`
                            }
                                )}`}
                            title="Classificação"
                          >
                            {slide.ranking}
                          </span>
                          <HashRouter>
                            <Link
                              to={
                                isAuthenticated()
                                  ? `/${slide.type === 'movie' ? 'filme' : 'serie'}/${slide.slug}`
                                  : '/login'
                              }
                              className="btn btn-default"
                            >
                              <span>Assista Agora</span>
                              <i className="material-icons">play_arrow</i>
                            </Link>
                          </HashRouter>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button
                type="submit"
                onClick={() => this.scrollSlider('row', 'FORWARD')}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  border: 0,
                }}
              >
                <Arrow style={{ height: 30, width: 30 }} />
              </button>
            </div>
            <br />
            <div className="container section remove-top-padding">
              {categories.length > 0 && (
                <form className="pull-right" role="search" method="POST" onSubmit={this.searchMovie}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar"
                      name="q"
                      onChange={e => this.setState({searchInput: e.target.value})}
                    />
                    <span className="">
                      <i className="fas fa-search" />
                    </span>
                  </div>
                </form>
              )}
              {this.state.loadingMovies && 'Carregando...'}
              {categories.map(category => (
                <div className="row" style={{ marginTop: 20 }} key={category.id}>
                  <div className="col-sm-12" id="">
                    <img
                      style={{ maxHeight: '72px' }}
                      src={category.image?.[Math.floor(Math.random() * category.image.length)] || null}
                      alt=""
                    />
                    <h2>
                      <span>{category.name}</span>
                    </h2>
                    <div>
                      {category.movies.length > 0 ? (
                        <div className="movie-category-wrapper">
                          {category.movies.map((movie, i) => i < 4 && (
                            <Link
                              className="movie-category-wrapper-item"
                              to={`/filme/${movie.slug}`}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: 205,
                                backgroundColor: '#FFFFFF',
                                border: '2px solid #e76115',
                                borderRadius: 10,
                              }}
                              key={movie.id_mv}
                            >
                              <div
                                className="poster-image-container"
                                style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                              >
                                <img
                                  src={movie.mv_poster}
                                  alt="Poster"
                                  style={{ borderTopLeftRadius: 9, borderTopRightRadius: 9, width: '100%' }}
                                />
                                <div
                                  className="poster-image-container-overlay"
                                  style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                                >
                                  <div style={{ borderRadius: 25, padding: 5, backgroundColor: '#e76115' }}>
                                    <i
                                      className="material-icons"
                                      style={{ fontSize: '42px', color: '#FFFFFF', opacity: 1 }}
                                    >
                                      play_arrow
                                    </i>
                                  </div>
                                </div>
                              </div>
                              <span
                                style={{
                                  color: '#e76115',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                {movie.mv_title}
                              </span>
                            </Link>
                          ))}
                          <div
                            className="category-show-more"
                            style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                          >
                            <HashRouter>
                              <Link to={`/categoria/${category.slug}`}>
                                <i className="material-icons" style={{ fontSize: '36px' }}>add_circle</i>
                              </Link>
                            </HashRouter>
                          </div>
                        </div>
                      ) : (
                        <div className="alert alert-warning" role="alert">
                          <p>Sem filmes nessa categoria</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <section className="dark">
              <div className="container section remove-bottom-padding">

                <div className="col-sm-12">
                  <h2>Em Breve</h2>
                  {inSoon ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
                      <button
                        type="submit"
                        onClick={() => this.scrollSlider('soonCar', 'BACKWARD')}
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                          border: 0,
                        }}
                      >
                        <Arrow style={{ height: 30, width: 30, transform: 'rotate(180deg)' }} />
                      </button>
                      <div id="soonCar" className="row row-wrapper">
                        <div className="row_og_inner">
                          {
                            !!inSoon && inSoon.map(slide => (
                              <div className="tile_og" key={slide.id}>
                                <div
                                  className="movie-category-wrapper-item"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: 205,
                                    backgroundColor: '#FFFFFF',
                                    border: '2px solid #e76115',
                                    borderRadius: 10,
                                  }}
                                >
                                  <div
                                    className="poster-image-container"
                                    style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                                  >
                                    <img
                                      src={slide.poster}
                                      alt="Poster"
                                      style={{ borderTopLeftRadius: 9, borderTopRightRadius: 9, width: '100%' }}
                                    />
                                    <div
                                      className="poster-image-container-overlay"
                                      style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                                    />
                                  </div>
                                  <span style={{
                                    color: '#e76115',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    marginTop: 10,
                                    marginBottom: 10,
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                  }}
                                  >
                                    {slide.title}
                                  </span>
                                </div>
                              </div>
                            ))
                            }
                        </div>
                      </div>
                      <button
                        type="submit"
                        onClick={() => this.scrollSlider('soonCar', 'FORWARD')}
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                          border: 0,
                        }}
                      >
                        <Arrow style={{ height: 30, width: 30 }} />
                      </button>
                    </div>
                  ) : (
                    <h1>Sem títulos novos a caminho...</h1>
                  )}
                </div>
              </div>
            </section>
          </div>
          <Loading
            open={loading}
            text="Estamos preparando o site, aguarde..."
            theme="white"
          />
        </div>
        <Footer />
      </>
    );
  }
}
