import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function TermsOfUse() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div>
        <div className="">
          <div id="app-show-movie" className="wrapper">
            <div className="container section single-movie">
              <h2>Termos de uso da VamuveTV</h2>
              <div className="faq-item-wrapper">
                <div className="faq-item">
                  <span>
                    A VamuveTV fornece um serviço personalizado de assinatura que permite aos nossos assinantes acessar
                    conteúdo de entretenimento (
                    <em>
                      “conteúdo da VamuveTV”
                    </em>
                    ) pela Internet, em determinados televisores, computadores e outros aparelhos conectados à Internet (
                    <em>
                      “aparelhos compatíveis com a VamuveTV”
                    </em>
                    ).
                    <br />
                    <br />
                    Estes Termos de uso regulam a sua utilização do serviço VamuveTV. Usados nestes Termos de uso, os termos
                    “serviço VamuveTV”, “nosso serviço” ou “o serviço” significam o serviço personalizado fornecido pela
                    VamuveTV, para buscar e acessar conteúdo de nossa plataforma, incluindo todos os recursos e funcionalidades,
                    recomendações e avaliações, nossos sites e as interfaces do usuário, assim como todo o conteúdo e software
                    associados ao serviço.

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      I - Assinatura
                    </p>
                    <ol style={{
                      listStyle: 'lower-alpha',
                    }}
                    >
                      <li>
                        Sua assinatura VamuveTV continuará até que seja cancelada. Para utilizar o serviço VamuveTV, você
                        precisa ter acesso à Internet e um aparelho compatível com a VamuveTV, bem como fornecer uma ou mais
                        Formas de Pagamento. “Forma de Pagamento” refere-se a uma forma de pagamento atualizada, válida e aceitável,
                        que poderá ser atualizada periodicamente, e que poderá incluir o pagamento por meio da sua conta com terceiros.
                        A menos que cancele a assinatura antes da data de cobrança, você nos autoriza a cobrar a taxa de assinatura do
                        próximo ciclo de faturamento usando a sua Forma de Pagamento (consulte “Cancelamento” abaixo).
                        <br />
                        <br />
                      </li>
                      <li>
                        Poderemos oferecer vários planos de assinatura, inclusive assinaturas disponibilizadas por terceiros
                        em combinação com a oferta de seus próprios produtos e serviços. A VamuveTV não se responsabiliza pelos
                        produtos e serviços fornecidos por tais terceiros. Alguns planos de assinatura podem possuir condições e
                        limitações diferentes, que serão reveladas no momento de sua inscrição ou em outras comunicações disponibilizadas
                        a você. Você pode encontrar detalhes específicos a respeito da sua assinatura da VamuveTV acessando o site
                        VamuveTV.com e clicando no link “Perfil”, exibido abaixo do nome de seu perfil na parte superior de todas as
                        páginas. As atualizações das condições e planos de pagamento serão especificadas em avisos na página
                        principal e em seu perfil.
                        <br />
                        <br />
                      </li>
                    </ol>

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      II - Ofertas promocionais.
                    </p>
                    A VamuveTV poderá disponibilizar ofertas, planos, produtos ou assinaturas promocionais especiais (“Ofertas”). A
                    elegibilidade para as Ofertas é determinada pela VamuveTV a seu exclusivo critério. A VamuveTV se reserva o direito
                    de revogar uma Oferta e suspender sua conta se determinarmos que você não é elegível. Moradores de uma mesma residência
                    com uma assinatura VamuveTV atual ou recente podem não ser elegíveis para Ofertas introdutórias específicas. A VamuveTV
                    poderá usar informações como identificação do aparelho, forma de pagamento ou email da conta usados em uma assinatura
                    VamuveTV atual ou recente para determinar a elegibilidade para a Oferta. Os requisitos de elegibilidade, bem como outras
                    limitações e condições, serão revelados quando você se inscrever para a Oferta ou em outras comunicações que
                    disponibilizaremos para você.

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      III - Faturamento e cancelamento
                    </p>
                    <ol style={{
                      listStyle: 'lower-alpha',
                    }}
                    >
                      <li>
                        Ciclo de faturamento. O preço da assinatura do serviço VamuveTV e todos os outros encargos incorridos por você em relação ao uso do serviço, tais como impostos e possíveis taxas de transação, serão cobrados, de acordo com a Forma de Pagamento escolhida, na data de pagamento específica indicada na página “Perfil”. A duração do ciclo de faturamento dependerá do tipo de assinatura escolhido quando você assinou o serviço. Em alguns casos, a data de pagamento poderá ser alterada, por exemplo, se a Forma de Pagamento não puder ser cobrada, se você alterar o plano de assinatura ou se a sua assinatura paga começar em um dia que não existe em um determinado mês. Visite o site VamuveTV.com e clique em “Detalhes de cobrança” na página “Perfil” para ver a data do seu próximo pagamento. A VamuveTV poderá solicitar antecipadamente uma autorização do valor de sua assinatura ou cobranças relacionadas ao serviço de sua Forma de Pagamento de várias formas, inclusive a autorização de até aproximadamente um mês de serviço, no ato de sua inscrição. Se você assinou a VamuveTV usando sua conta com um terceiro como Forma de Pagamento, poderá encontrar os dados de cobrança da assinatura VamuveTV acessando a sua conta com o referido terceiro.
                        <br />
                        <br />
                      </li>
                      <li>
                        Formas de Pagamento. Para utilizar o serviço VamuveTV, você precisa fornecer uma ou mais Formas de Pagamento. Você nos autoriza a cobrar de qualquer Forma de Pagamento associada à sua conta caso a sua Forma de Pagamento principal seja negada ou não esteja mais disponível para nós para o pagamento do valor da assinatura e você concorda que as Formas de Pagamento disponíveis tanto para operações de débito como de crédito poderão ser processadas de ambas as formas. Você continua responsável por todos os valores não pagos. Se um pagamento não for realizado com sucesso, devido à perda de validade, insuficiência de saldo ou qualquer outro motivo, e você não cancelar a sua conta, poderemos suspender o seu acesso ao serviço até que consigamos fazer a cobrança de uma Forma de Pagamento válida. Para algumas Formas de Pagamento, as instituições financeiras e/ou os prestadores de serviço de meios de pagamento poderão cobrar determinadas taxas, como taxas sobre transações financeiras internacionais ou outras taxas relacionadas à Forma de Pagamento. A cobrança de impostos locais pode variar de acordo com a Forma de Pagamento usada. Consulte a instituição financeira da sua Forma de Pagamento para obter mais detalhes.
                        <br />
                        <br />
                      </li>
                      <li>
                        Atualização das Formas de Pagamento. Você pode atualizar as suas Formas de Pagamento acessando a página “Perfil”. Nós também podemos atualizar as suas Formas de Pagamento com informações fornecidas pelas respectivas instituições financeiras, instituições de pagamento e/ou pelos prestadores de serviços de meios de pagamento. Após qualquer atualização, você nos autoriza a continuar a cobrar a(s) respectiva(s) Forma(s) de Pagamento.
                        <br />
                        <br />
                      </li>
                      <li>
                        Cancelamento. Você pode cancelar sua assinatura VamuveTV a qualquer momento e você continuará a ter acesso ao serviço VamuveTV até o fim do período de faturamento. Na extensão permitida pelas leis aplicáveis, os pagamentos não são reembolsáveis e a VamuveTV não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por conteúdo VamuveTV não utilizado. Para cancelar, acesse a página “Perfil” e siga as instruções de cancelamento. Se você cancelar sua assinatura, sua conta será automaticamente encerrada ao fim do período de cobrança em andamento. Para verificar quando sua conta será encerrada, clique na opção “Detalhes de cobrança” da página “Perfil”. Se você se inscreveu na VamuveTV indicando como Forma de Pagamento uma conta junto a terceiros e desejar cancelar sua assinatura VamuveTV, talvez seja preciso efetuar o cancelamento juntamente a tais terceiros, por exemplo, acessando sua conta com esse terceiro e desativando a renovação automática ou cancelando a assinatura do serviço VamuveTV por meio desse terceiro.
                        <br />
                        <br />
                      </li>
                      <li>
                        Alterações de preços e planos de assinatura. De tempos em tempos, poderemos alterar nossos planos de assinatura e os preços de nosso serviço. Os valores de assinatura poderão ser revisados periodicamente para melhor adequação ao contínuo aprimoramento de nosso serviço. Nós também poderemos ajustar o valor de sua assinatura anualmente, ou com maior frequência se permitido pela legislação vigente, de acordo com a inflação estabelecida pelo Índice Geral de Preços do Mercado (IGP-M), publicado pela Fundação Getúlio Vargas, ou outro índice equivalente aplicável ao serviço VamuveTV. Quaisquer alterações de preço ou alterações em seus planos de assinatura serão aplicadas somente 30 dias após o envio da notificação a você.
                        <br />
                        <br />
                      </li>
                    </ol>

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      IV - Serviço VamuveTV
                    </p>
                    <ol style={{
                      listStyle: 'lower-alpha',
                    }}
                    >
                      <li>
                        Você deve ter pelo menos 18 anos para assinar o serviço VamuveTV. Menores de idade podem usar o serviço somente sob a supervisão de um adulto.
                        <br />
                        <br />
                      </li>
                      <li>
                        O serviço VamuveTV e todo o conteúdo acessado por intermédio de nossos serviços destinam-se exclusivamente para uso pessoal e não comercial, não podendo ser compartilhados com pessoas de fora da sua família. Durante sua assinatura VamuveTV, a VamuveTV concede a você um direito limitado, não exclusivo e intransferível para acessar o serviço VamuveTV e o conteúdo da VamuveTV. Exceto pelo descrito acima, nenhum outro direito, titularidade ou participação lhe é concedido. Você concorda em não utilizar o serviço em exibições públicas.
                        <br />
                        <br />
                      </li>
                      <li>
                        Você pode acessar o conteúdo da VamuveTV, exclusivamente no Brasil, onde oferecermos nosso serviço tanto para as obras já decaídas em domínio público – objeto de nosso acervo – quanto aquelas que tivermos licenciado conteúdo que ainda esteja eventualmente protegido. O conteúdo que pode estar disponível irá variar segundo a sua localização geográfica e será alterado periodicamente. O número de aparelhos nos quais você pode assistir o conteúdo simultaneamente depende do plano de assinatura escolhido e está especificado na página “Perfil”.
                        <br />
                        <br />
                      </li>
                      <li>
                        Nosso conteúdo divide-se em:
                        <br />
                        <ol style={{
                          listStyle: 'number',
                        }}
                        >
                          <li>
                            Obras audiovisuais já caídas em domínio público: Obras cujos direitos patrimoniais (
                            <em>
                              e conexos quando e se aplicáveis
                            </em>
                            )já decaíram, seja pelo decurso de lapso temporal (
                            <em>
                              que é por nós averiguado em riqueza de detalhes para cada material acessível em nosso acervo,
                              aplicando-se como norte a legislação brasileira e suas variações ao longo das décadas e ao tempo em que
                              foram produzidas, incluindo a verificação em relação ao seu País de origem caso a obra já assim tenha
                              decaído em razão de prazo inferior ou em relação aos Países, Principados, Estados em que não tenha proteção alguma
                            </em>
                            ), como pela eventual perda de direitos em seu País de origem pela falta e renovação de licenças ou demais
                            circunstâncias de perda de direito no País de origem em se tratando de obras estrangeiras, ou ainda nas situações
                            em que haja perda de direitos por fatores não-relacionados à questão temporal conforme a legislação brasileira.
                            Não se é permitido à ninguém reivindicar a autoria ou direitos patrimoniais ou conexos de obras já caídas em domínio
                            público, razão pela qual a VamuveTV não apenas não reivindica (
                            <em>sob qualquer pretexto</em>
                            ) a autoria de obras já
                            caídas em domínio público, como também zela pela preservação e manutenção de obras assim já estabelecidas e que
                            formam nossa coletânea e compilação permitindo-se ao usuário o acesso à estas coleções mediante taxa de assinatura
                            para a liberação do acesso ao sistema, conforme o plano de acesso escolhido;
                            <br />
                            <br />
                          </li>
                          <li>
                            Obras audiovisuais, literárias, artísticas, gráficas, fonográficas, coletâneas, compilações, ilustrações, obras
                            sonorizadas, textos, programas de computador, adaptações ou transformações de obras audiovisuais já caídas em domínio
                            público (
                            <em>e conexos quando e se aplicáveis</em>
                            ), ou demais obras intelectuais criadas, produzidas, dirigidas, escritas ou que de qualquer forma pertençam à
                            VamuveTV, tem os seus direitos autorais à nós garantidos e protegidos, ficando ao exclusivo critério da VamuveTV
                            dispô-las, como melhor entender, podendo inclusive ceder total ou parcialmente seus direitos de uso para terceiros.
                            A eventual adaptação ou transformação de obras audiovisuais (vale dizer: recriações, refilmagens ou remakes,
                            conversões de obras literárias para cinematográficas ou televisivas etc) cujo conteúdo original já tenha caído
                            em domínio público, apenas é assim considerada quando a adaptação ou transformação implicar na criação de uma outra
                            obra audiovisual completa diversa da original e que tenha sido apenas baseada na anterior (
                            <em>de maneira análoga ao que se faz ao adaptar ou transformar uma obra literária para uma cinematográfica</em>
                            ), sendo para tanto necessária produção própria, novos cenários, novos enredos, novos roteiros, novos atores e demais
                            elementos do elenco, apenas utilizando como fonte de inspiração intelectual a obra original já decaída. É terminantemente
                            proibido aos licenciados, parceiros, afiliados, assinantes, associados e à terceiros em geral, a reivindicação de
                            autoria em cujas obras já tenham caído em domínio público (
                            <em>
                              inda que reproduzidas para serem editadas, restauradas ou transferidas/reproduzidas de um meio para outro,
                              as quais sendo o objeto da obra principal/primígena as assemelhe à plágio de obra já caída em domínio público,
                              com a finalidade de desvirtuar sua natureza enquanto obra pertencente ao domínio público. Aplica-se esta
                              ressalva também o item anterior: ”Obras audiovisuais já caídas em domínio público”
                            </em>
                            ), salvo aquelas efetivamente adaptadas ou transformadas para esta finalidade, cujo resultado seja a efetiva
                            criação de nova obra (
                            <em>assim considerada</em>
                            ) baseada na anterior (
                            <em>e/ou, portanto, por ela derivada</em>
                            ) e por nós licenciada;
                            <br />
                            <br />
                          </li>
                          <li>
                            Obras que não tenham decaído ao domínio público (
                            <em>quais sejam: as licenciadas</em>
                            ), sejam elas: audiovisuais, literárias, artísticas, fonográficas, obras sonorizadas, programas de
                            computador, programas televisivos, humorísticos, telejornalismo, variedades, programas criados ou
                            veiculados em redes sociais, adaptações ou transformações de obras audiovisuais de terceiros ou que
                            de qualquer foram licenciadas à VamuveTV. Embora o foco principal da VamuveTV sejam obras já decaídas ao
                            domínio público, eventualmente poderemos veicular ou dispor de obras de terceiros que ainda estejam sob
                            proteção, mediante licenciamento para a VamuveTV;
                            <br />
                            <br />
                          </li>
                          <li>
                            Obras culturais, artísticas, jornalísticas, referenciais, historiográficas ou intelectuais acessíveis ao público:
                            Fica ao exclusivo critério da VamuveTV, a exibição pública de obras de nossa autoria (ou seja, fora da área de
                            assinantes), aí incluindo aquelas inerentes às nossas coleções ou coletâneas, ou obras criadas ou produzidas em
                            nossos estúdios ou filiados, para que sejam apresentadas em escolas, centros culturais e/ou educacionais, mostras
                            de cinema, televisão, teatro ou demais atividades relacionadas à disseminação cultural, lazer ou educacional, museus,
                            bibliotecas, Instituições públicas ou privadas ligadas à área cultural ou de entretenimento ou aquelas que, de alguma
                            forma, sirvam à manutenção, perpetuação, aprimoramento ou difusão cultural, educacional, entretenimento ou de lazer.
                            As obras à nós licenciadas constantes no item anterior (d”3”) somente serão exibidas publicamente mediante permissão
                            nos respectivos contratos de licenciamento;
                            <br />
                            <br />
                          </li>
                          <li>
                            Acervo cinematográfico: Além de coletânea de obras audiovisuais, a Filmoteca VamuveTV reúne, organiza e
                            disponibiliza informações sobre toda a produção audiovisual produzida no mundo desde o século XIX até
                            meados da década de 50. Toda a atividade relacionada ao acervo é desenvolvida na VamuveTV e as informações
                            referentes aos filmes, atores, diretores, membros do elenco, referencias historiográficas e demais
                            informações relativas à cada filme ou pessoa que dele tenha participado, está em constante atualização.
                            Atualmente, dispomos no acervo a informação de aproximadamente 200.000 filmes produzidos até aquela época,
                            além de informações de aproximadamente 100.000 membros de seus elencos. As informações reunidas e sistematizadas
                            em nosso acervo, fazem parte integrante de nossa coletânea e coleção, e são baseadas na pesquisa em
                            documentação fílmica e não fílmica. Entre as fontes de dados para esse trabalho está toda uma gama da análise
                            de documentação fílmica e não fílmica: dentre eles recortes de jornais, roteiros e releases de filmes, textos
                            de locução para cinejornais, fichas de depósito legal, livros e periódicos, pesquisas acadêmicas, sites
                            institucionais dentre outras fontes de informações coletadas em sites ou instituições especializadas em cinema,
                            aí incluindo (
                            <em>embora não exclusivamente</em>
                            ) aquelas destinadas à obras decaídas em domínio público. A pesquisa com estas diferentes tipologias de
                            fontes possibilita descrições técnicas detalhadas e informações de caráter histórico para grande parte dos
                            registros. A constante atualização da base de dados também permite a inclusão de informações encaminhadas por
                            pesquisadores e interessados na cultura cinematográfica e audiovisual mundial. Com o tempo iremos ampliando nossas
                            fontes referenciais ou historiográficas ou informativas para a inserção de dados relativos à filmes, seriados, desenhos,
                            series televisivas ou cinematográficas, ou de internet, até os dias atuais. Buscamos manter nos textos, a fidelidade de
                            seus conteúdos, buscando, sempre que possível redigi-los tal e qual foram redigidos à época de suas publicações, à
                            despeito de alterações de acordos ortográficos brasileiros ao longo das décadas. Por vezes, empregamos em nossa
                            redação das sinopses, resenhas, biografias ou até mesmo das críticas de cinema que realizamos (
                            <em>seja para destinação informativa, jornalística, diária ou periódica, literária ou de qualquer forma escrita</em>
                            ) a transcrição total ou parcial de textos antigos (
                            <em>devidamente referenciados conforme o caso</em>
                            ) apenas atualizando conforme as regras ortográficas atuais para melhor compreensão do texto, como por exemplo, o
                            uso de “ph” que atualizamos para “f”, “ll” que atualizamos para apenas um “l” etc. Estendemos que esta é uma forma
                            prazerosa em transmitir a informação mantendo o tanto quanto possível a fidelidade ao texto original, para que
                            permaneça avivada sua idéia central, ficando já ciente o usuário de que, todo texto produzido na plataforma deve ser
                            analisado também em relação ao caráter temporal no qual eles foram escritos, ou seja, procuramos manter o hábito em
                            se contextualizar as redações originais e seus respectivos conteúdos;
                            <br />
                            <br />
                          </li>
                          <li>
                            Obras pornográficas: É terminantemente proibido aos parceiros, filiados, associados, anunciantes, licenciados
                            ou licenciantes a veiculação, divulgação, promoção, incentivo ou outros atos que de qualquer forma busquem
                            relativizar, enaltecer, disseminar ou difundir atividades pornográficas e/ou parafílicas (
                            <em>relacionadas à parafilias e/ou perturbações derivadas destas</em>
                            ) dentro de nossa plataforma ou que, de qualquer forma, vincule nossa marca com este tipo de atividade.
                            Em nossa plataforma, todo e qualquer conteúdo que a VamuveTV considerar pornográfico não tem apoio, incentivo
                            ou espaço algum. Todo conteúdo em nossa plataforma é destinado ao ambiente familiar, garantindo-se a manutenção
                            e perpetuação da moral, dos costumes, da tradição, dos valores, devidamente contextualizados à época de suas
                            criações primígenas e mantidos tanto para a geração presente como para as vindouras. Conteúdos eróticos ou mais
                            sensíveis (
                            <em>mesmo os relativos à anúncios de terceiros</em>
                            ) serão analisados em cada caso, cabendo ao exclusivo critério da VamuveTV a opção em permitir ou proibir
                            suas exibições ou formas de acesso;
                            <br />
                            <br />
                          </li>
                          <li>
                            Comentários de assinantes ou do público em geral: Eventualmente poderemos permitir áreas específicas em nossa
                            plataforma para que os assinantes ou público em geral teçam seus comentários à respeito dos filmes, sinopses,
                            resenhas, artigos ou demais textos publicados na plataforma. Tão logo essa opção esteja disponível, poderemos incluir
                            as formas como poderão ser feitos estes comentários em atualizações destes Termos de Uso. Portanto, se optarmos em
                            disponibilizar esta opção, fiquem atentos às atualizações do presente Termo.
                            <br />
                            <br />
                          </li>
                        </ol>
                      </li>
                      <li>
                        O serviço VamuveTV, inclusive o catálogo de conteúdo, é atualizado regularmente. Além disso, diferentes aspectos do serviço são testados de forma contínua, incluindo nossos sites, as interfaces de usuário, os recursos promocionais e a disponibilidade do conteúdo da VamuveTV. Poderemos dispor ao usuário e/ou assinante a possibilidade de participação em testes relacionados ao aprimoramento da plataforma. Para isso, basta acessar as opções relacionada aos testes caso estejam disponíveis na plataforma.
                        <br />
                        <br />
                      </li>
                      <li>
                        Nenhum do conteúdo da VamuveTV está disponível para download e visualização off-line. É expressamente proibido a gravação em CDs, DVDs ou qualquer outra forma de reprodução, bem como você não pode realizar engenharia reversa, ou utilizar programas de computador ou sistemas correlatos destinados à invasão ou burla de nossos sistemas, bem como não pode copiar ou utilizar qualquer elemento ou conteúdo de nosso website fora do ambiente de nossa plataforma. Em que pese nosso acervo audiovisual ser composto, em sua maioria, por obras já caídas em domínio público, tanto nossa marca registrada (VamuveTV®), logomarca, personagens, como alguns de nossos programas e coletâneas, composições, textos, produções ou demais criações intelectuais são de propriedade exclusiva de VamuveTV, sendo vedada sua utilização sem nossa expressa autorização. Além de programas, filmes séries, desenhos ou demais obras intelectuais que nós venhamos à produzir, também poderemos incluir na programação ou em nossa plataforma, programas criados por nossos parceiros comerciais, razão pela qual por questões ligadas à segurança e direitos autorais inerentes às produções de nossos parceiros, fica igualmente vedada a utilização fora de nossa plataforma.
                        <br />
                        <br />
                      </li>
                      <li>
                        Você concorda em usar o serviço VamuveTV, incluindo todos os recursos e funcionalidades associadas de acordo com as
                        leis, regras e regulamentos aplicáveis ou outras restrições de uso do serviço ou conteúdo previstas nas mesmas.
                        Você também concorda em não arquivar, reproduzir, distribuir, modificar, exibir, executar, publicar, licenciar ou
                        criar trabalhos derivados, colocar à venda ou utilizar (
                        <em>exceto nas formas expressamente autorizadas por estes Termos de uso</em>
                        ) o conteúdo e as informações contidas ou obtidas do serviço VamuveTV ou por meio deste. Você também concorda em não
                        contornar, remover, alterar, desativar, degradar ou adulterar quaisquer das proteções de conteúdo do serviço VamuveTV,
                        usar qualquer robô, spider, scraper ou outros meios automatizados para acessar o serviço VamuveTV, descompilar, executar
                        engenharia reversa ou desmembrar qualquer software ou outros produtos ou processos acessíveis pelo serviço VamuveTV,
                        inserir qualquer código ou produto ou manipular o conteúdo do serviço VamuveTV de qualquer forma ou usar métodos de
                        data mining, coleta de dados ou extração de dados. Além disso, você concorda em não fazer upload, publicar, enviar por
                        e-mail, comunicar ou transmitir de qualquer forma qualquer material designado para interromper, destruir ou limitar a
                        funcionalidade de qualquer software, hardware ou equipamento de telecomunicações associado ao serviço VamuveTV, incluindo
                        vírus de software, código, arquivos ou programas. A VamuveTV poderá cancelar ou restringir seu uso do serviço se você
                        violar estes Termos de uso ou se envolver no uso ilegal ou fraudulento do serviço.
                        <br />
                        <br />
                      </li>
                      <li>
                        A qualidade da imagem do conteúdo da VamuveTV pode variar de aparelho para aparelho e pode ser afetada por diversos
                        fatores, incluindo sua localização, a largura de banda disponível, a velocidade da sua conexão com a Internet ou em
                        algumas ocasiões pelo estado das obras audiovisuais (
                        <em>
                          que pelas suas antiguidades podem apresentar incorreções ou pequenas falhas pelo decurso do longo período
                          de tempo em que foram produzidas. Embora enveredemos esforços para restaurá-las ou apresenta-las da melhor
                          maneira quanto for possível
                        </em>
                        ). A disponibilidade de alta definição (HD), ultra-alta definição (Ultra HD) e High Dynamic Range (HDR) está sujeita
                        aos seus serviços de Internet e recursos do aparelho usado. NEM TODO O CONTEÚDO ESTÁ DISPONÍVEL EM TODOS OS FORMATOS,
                        COMO HD, ULTRA HD OU HDR, E NEM TODOS OS PLANOS DE ASSINATURA PERMITEM O RECEBIMENTO DE CONTEÚDO EM TODOS OS FORMATOS.
                        AS CONFIGURAÇÕES PADRÃO DE REPRODUÇÃO EM REDES DE TELEFONIA CELULAR EXCLUEM CONTEÚDO HD, ULTRA HD E HDR. A VELOCIDADE DE
                        CONEXÃO MÍNIMA PARA QUALIDADE DE DEFINIÇÃO PADRÃO (SD) É 1,0 MBPS. NO ENTANTO, RECOMENDAMOS UMA CONEXÃO MAIS RÁPIDA PARA
                        OBTER UMA MELHOR QUALIDADE DE VÍDEO. RECOMENDAMOS UMA VELOCIDADE MÍNIMA DE DOWNLOAD DE 3,0 MBPS POR TRANSMISSÃO PARA
                        RECEBER CONTEÚDO EM HD (DEFINIDO COMO RESOLUÇÃO 720P OU SUPERIOR). Você se responsabiliza por todas as tarifas de
                        acesso à Internet. Por favor, consulte seu provedor de Internet para obter informações sobre os custos de utilização
                        de dados. O tempo necessário para começar a assistir ao conteúdo da VamuveTV poderá variar de acordo com uma série de
                        fatores, incluindo a sua localização, a largura de banda disponível no momento, o conteúdo que você tenha selecionado
                        e as configurações do aparelho compatível com a VamuveTV utilizado.
                        <br />
                        <br />
                      </li>
                      <li>
                        Todo e qualquer software que venha ou foi desenvolvido pela ou para a VamuveTV, somente pode ser usado para a transmissão autorizada e acesso ao conteúdo da VamuveTV em aparelhos compatíveis com a VamuveTV. Esses softwares poderão variar conforme o aparelho e mídia. A funcionalidade e os recursos também poderão variar segundo o aparelho utilizado. Você concorda que o uso do serviço poderá exigir softwares de terceiros, sujeito a licenças de terceiros. Você concorda que poderá receber automaticamente versões atualizadas do software da VamuveTV e de software relacionado de terceiros, sempre que estes estiverem disponíveis.
                      </li>
                    </ol>

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      V - Senhas e acesso à conta.
                    </p>
                    O assinante que criou a conta VamuveTV e cuja Forma de Pagamento é cobrada pelo serviço é responsável por
                    qualquer atividade que ocorra na conta VamuveTV. Para manter o controle sobre a conta e evitar que qualquer
                    pessoa possa acessá-la (
                    <em>o que incluiria informações dos títulos assistidos da conta, além de seus dados pessoais</em>
                    ), o Proprietário da Conta deve manter o controle sobre os aparelhos compatíveis com a VamuveTV utilizados
                    para acessar o serviço e não revelar a ninguém a senha ou os detalhes da Forma de Pagamento associada à conta.
                    Você é responsável por atualizar e manter exatas as informações fornecidas à VamuveTV relativas à sua conta.
                    A VamuveTV poderá cancelar ou suspender a sua conta para proteger você, a VamuveTV ou nossos parceiros contra
                    falsidade ideológica ou outras atividades fraudulentas.

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      VI - Garantias e isenção de responsabilidades.
                    </p>
                    O SERVIÇO VAMUVETV É FORNECIDO “NO ESTADO EM QUE SE ENCONTRA”, SEM GARANTIAS OU CONDIÇÕES. ESPECIFICAMENTE,
                    O NOSSO SERVIÇO PODE NÃO ESTAR LIVRE DE INTERRUPÇÕES OU ERROS. VOCÊ RENUNCIA A TODAS E QUAISQUER INDENIZAÇÕES
                    POR DANOS INDIRETOS, MORAIS E MATERIAIS CONTRA A VAMUVETV. A VAMUVETV NÃO É RESPONSÁVEL PELA QUALIDADE DE SUA
                    CONEXÃO COM A INTERNET. Estes Termos não limitarão nenhuma garantia e nenhum direito obrigatório não renunciável
                    de proteção do consumidor que se aplicam a você.

                    <p style={{
                      textAlign: 'left', fontWeight: 'bold', maxWidth: '100%', padding: '1rem 0 0.7rem', fontSize: '1.2rem',
                    }}
                    >
                      VII - Diversos.
                    </p>
                    <ol style={{
                      listStyle: 'lower-alpha',
                    }}
                    >
                      <li>
                        Legislação aplicável. Estes Termos de uso devem ser regidos por e interpretados de acordo com a legislação do Brasil.
                        <br />
                        <br />
                      </li>
                      <li>
                        Materiais não solicitados. A VamuveTV não aceita materiais ou ideias não solicitados de conteúdo da VamuveTV e não é responsável pela semelhança de seu conteúdo ou programação em qualquer mídia com materiais ou ideias transmitidos à VamuveTV.
                        <br />
                        <br />
                      </li>
                      <li>
                        Atendimento ao cliente. Para obter mais informações sobre o serviço VamuveTV, suas funcionalidades e recursos, ou caso precise de ajuda com sua conta, por favor, acesse o Centro de ajuda VamuveTV no site vamuvetv.com.br ou vamuvetv.com.A VamuveTV ajudará você de outras formas. No caso de quaisquer conflitos entre estes Termos de uso e informações fornecidas pelo atendimento ao cliente ou outras partes dos nossos sites, estes Termos de uso prevalecerão.
                        <br />
                        <br />
                      </li>
                      <li>
                        Subsistência. Se qualquer disposição ou disposições destes Termos de uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade, legalidade e aplicabilidade das demais disposições devem permanecer em pleno vigor.
                        <br />
                        <br />
                      </li>
                      <li>
                        Alterações dos termos de uso e cessão. A VamuveTV poderá alterar estes Termos de uso periodicamente. Notificaremos você com pelo menos 30 dias de antecedência antes que as alterações se apliquem a você. A qualquer momento, a VamuveTV poderá ceder ou transferir o nosso contrato com você, inclusive nossos direitos e obrigações associados. Você concorda em cooperar com a VamuveTV nessas cessões ou transferências.
                        <br />
                        <br />
                      </li>
                      <li>
                        Comunicações eletrônicas. Enviaremos informações relacionadas à sua conta (
                        <em>
                          por exemplo, autorizações de pagamento, cobranças, alterações de senha ou Forma de Pagamento,
                          mensagens de confirmação, notificações
                        </em>
                        ) somente em formato eletrônico como, por exemplo, por meio de emails para o endereço fornecido durante a inscrição.
                      </li>
                    </ol>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
