import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

import './index.css';

export default function Faq() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div>
        <div className="">
          <div id="app-show-movie" className="wrapper">
            <div className="container section single-movie">
              <h2>Faq</h2>
              <div className="faq-item-wrapper">
                <div className="faq-item">
                  <h3>O que é a VamuveTV?</h3>
                  <span>
                    A VamuveTV é um serviço inovador na área de entretenimento,
                    que envolve o acesso à informação de material audiovisual,
                    notícias de cinema, TV, artistas, informação em geral sobre
                    a área de entretenimento clássico, permitindo aos clientes
                    assistir a uma ampla variedade de filmes, desenhos, documentários,
                    programas de televisão e séries clássicas da TV de forma simples e
                    sem sair de casa. Com a VamuveTV, você tem acesso ilimitado ao nosso
                    conteúdo, sempre sem comerciais enquanto você assiste. Aqui você sempre
                    encontra novidades. A cada mês, adicionamos novas séries, filmes,
                    desenhos, programas, informação, artigos de cinema, TV, humor, enfim,
                    entretenimento em geral.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Qual nossa área de especialização?</h3>
                  <span>
                    Nossa especialização e foco principal são obras audiovisuais já decaídas
                    em domínio público assim definidas na legislação brasileira, devidamente
                    adequadas à legislação internacional de cada País que outrora possuía direitos
                    sobre as obras produzidas. Ou seja, as obras audiovisuais aqui apresentadas
                    (
                    <em>tanto nacionais como internacionais</em>
                    ) são inerentes àquelas ao domínio público.
                    Tendo em vista que cada País originário da obra possui legislação, condições e
                    prazos diversos para o decaimento em domínio público, nós averiguamos detalhadamente
                    a situação de cada obra-audiovisual em nosso acervo, checando a situação de cada uma
                    delas em sites, institutos ou demais organizações relacionadas ou especializadas em
                    obras caídas em domínio público. A checagem detalhada é feita tanto em relação ao
                    prazo quanto à eventual perda de direitos decorrentes de ausência de renovação,
                    incorreção de licenciamento ou qualquer outra razão em seu País de origem que tenha
                    feito o direito patrimonial ter perecido ou nas hipóteses em que no Brasil estas
                    obras já não tenham mais proteção (
                    <em>
                      repita-se por decaimento de prazo ou qualquer
                      outra condição de perda do direito
                    </em>
                    ). Nos Países de origem cujo prazo de ingresso
                    de domínio público seja superior ao brasileiro, é terminantemente proibida a
                    veiculação ou utilização de nossa plataforma para a visualização de obras em
                    cujos países ainda possa haver alguma proteção, razão pela qual, ao menos nesse
                    momento, a utilização de nossa plataforma é restrita ao território brasileiro.
                    <br />
                    As únicas exceções às normas e regramento relacionadas ao domínio público, nesse momento,
                    são obras resultantes de programas, filmes, seriados ou desenhos produzidos por nossa
                    empresa ou aquelas eventualmente produzidas por nossos parceiros comerciais licenciados,
                    estando estas, portanto, protegidas de acordo com a legislação brasileira, sendo vedada a
                    utilização destas fora do ambiente de nossa plataforma, sem nossa expressa autorização
                    (
                    <em>ou no caso de obras de nossos parceiros comerciais, sem a autorização destes</em>
                    ).
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Onde posso assistir a VamuveTV?</h3>
                  <span>
                    Você pode assistir online em qualquer aparelho conectado à Internet como computadores
                    (PC e MAC), smartphones e tablets através das URLs:
                    {' '}
                    {' '}
                    <a href="https://www.vamuvetv.com.br" target="_blank" rel="noopener noreferrer">www.vamuvetv.com.br</a>
                    {' '}
                    {' '}
                    ou
                    {' '}
                    {' '}
                    <a href="https://www.vamuvetv.com" target="_blank" rel="noopener noreferrer">www.vamuvetv.com</a>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>A VamuveTV é focada ao ambiente público?</h3>
                  <span>
                    Não. Você somente poderá assistir e ter acesso ao material audiovisual
                    do site, incluindo a visualização, execução e locação de filmes, séries
                    ou desenhos, após se tornar assinante e estar logado no site e os assistindo
                    no âmbito doméstico e, portanto, privado. Acreditamos que a verdadeira experiência
                    de imersão se faz no âmbito familiar, doméstico, da mesma forma como era antigamente
                    quando se locavam VHS para que as pessoas assistissem em casa, junto à família ou
                    até sozinhos no conforto do lar.
                    <br />
                    Entretanto, caso você não seja ainda um assinante poderá ter acesso às informações
                    (
                    <em>inda que limitadas</em>
                    ) sobre filmes, seriados, atores diretores, sinopses, biografias, área de notícias,
                    crônicas, críticas ou demais informações ou elementos do site que, porventura, tornemos
                    de acesso público e gratuito.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>
                    Eu tenho um comércio ou local de ambientação pública (
                    <em>cinema ao ar livre, restaurante, bar, teatro público, espetáculo ao ar livre etc</em>
                    ) posso assinar a VamuveTV e dispor aos meus clientes ou ao público em geral ?
                  </h3>
                  <span>
                    No presente momento, não. A plataforma e o site VamuveTV somente deve ser acessada no ambiente
                    doméstico e privado, sendo vedado o acesso de terceiros ou execução pública em geral do conteúdo
                    de nosso site. A utilização dos serviços da VAMUVETV é restrita ao âmbito doméstico e, portanto,
                    individual e privado. É terminantemente proibido o acesso e execução pública do material constante
                    em nosso site que seja exclusivo à área de assinantes, bem como não é permitida a utilização de nosso
                    material para a comercialização ou qualquer forma que implique na obtenção de lucro, direto ou indireto,
                    nem tampouco é permitida a exibição em comércios ou demais locais de acesso público. A utilização diversa
                    do material da VAMUVETV, ou seja toda aquela que não se restrinja ao ambiente doméstico e privado, pode
                    acarretar no cancelamento imediato dos serviços. No entanto, pessoas jurídicas que queiram veicular nossos
                    programas, personagens (
                    <em>TVNautas ou outros à serem criados</em>
                    ) ou material reservado, estamos idealizando
                    formas de poder lhes atender como, por exemplo, a realização de eventos, shows, ou até mesmo a
                    realização de parcerias de negócios para a eventual concessão de licenças sobre os direitos autorais relativos
                    à comercialização de nossos personagens, produtos, serviços, programação exclusiva ou quaisquer outras
                    propriedades intelectuais pertencentes à VamuveTV.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>O que são os TVNautas?</h3>
                  <span>
                    Os TVNautas compõem-se de uma turma de personagens criados, idealizados e registrados para
                    e pela VamuveTV para lhes ampliar a experiência na área de entretenimento. Criamos e registramos
                    as personagens (
                    <em>Red Tenna, Fred Fante, Boogaloo, James Boing e outros</em>
                    ) em nossos estúdios para
                    buscar relembrar à vocês a verdadeira arte da animação e desenhos clássicos, buscando novas formas
                    de lhes entreter tanto em nosso website como em nossas plataformas sociais.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>
                    Eu posso utilizar e/ou comercializar os TVNautas, logotipo, nome ou outros personagens
                    ou os demais elementos do site, redes sociais ou outros veículos da VamuveTV ?
                  </h3>
                  <span>
                    Não. Os TVNautas e todos os demais personagens, elementos gráficos, estilo visual e/ou
                    estrutural, banco de dados, acervo, compilações, coletâneas, programas e o que mais formam
                    e compõe o site, redes sociais e plataforma, constituem propriedade intelectual da VamuveTV
                    e são devidamente registrados de cujos direitos pertencem exclusivamente à
                    {' '}
                    <em>VamuveTV®</em>
                    , além
                    de nosso próprio nome comercial VamuveTV ser marca registrada, não sendo permitido,
                    portanto, sua utilização (
                    <em>comercial ou não</em>
                    ) sem a nossa expressa autorização. Se você tem
                    interesse em comercializar ou, de alguma forma, obter a licença de uso para nossos personagens
                    ou utilizar nossa marca VamuveTV e personagens registrados como os TVnautas (
                    <em>ou outros por nós criados</em>
                    ) em estamparia, brinquedos, bonecos, desenhos, animações, programas de televisão, sites ou demais
                    projetos ou empreendimentos, por favor, entre em contato com nossa área comercial para verificarmos
                    sua proposta em:
                    {' '}
                    <a href="mailto:comercial@vamuvetv.com.br">comercial@vamuvetv.com.br</a>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como é a programação?</h3>
                  <span>
                    Além das informações e artigos de cinema e TV, atores, atrizes, elenco,
                    sinopse, disponibilizamos centenas de filmes, desenhos e séries clássicas
                    e vintage, para assistir online em qualquer aparelho compatível com a VamuveTV.
                    Não há comerciais durante a execução dos filmes, e você pode pausar, retroceder,
                    avançar ou assistir de novo quantas vezes quiser. Por ser um conteúdo antigo
                    (
                    <em>a vasta maioria de nosso acervo é constituído por obras já decaídas em domínio público</em>
                    )
                    apesar de alguns títulos terem sido filmados ainda em qualidade de SD
                    (
                    <em>ou alguns serem oriundos de telecinagens em 35, 16 mm, super8 ou outras matrizes mais antigas</em>
                    ),
                    nossos estúdios os estão aperfeiçoando e restaurando para que a qualidade se torne mais
                    compatível com HD, dando uma roupagem única aos filmes clássicos e vintage de forma a aumentar
                    a qualidade geral para melhor entretenimento. Apesar do trabalho de restauro, resgate e
                    recuperação, alguns filmes podem conter pequenas imperfeições ou falhas, entretanto, isso não
                    retira a magia ou o prazer em assisti-los como um todo.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>A VamuveTV é adequada para crianças?</h3>
                  <span>
                    A experiência infantil da VamuveTV faz parte da sua assinatura para que as crianças se divirtam
                    junto à seus pais, tanto em relação à séries, filmes e desenhos familiares ou acessem nossos
                    jogos e passatempos disponíveis na plataforma, desde que a utilização seja feita sempre com
                    a supervisão dos pais. Nosso conteúdo é focado ao ambiente familiar, servindo à adultos e
                    crianças que gostem de entretenimento clássico. Para tanto, os filmes, séries e desenhos
                    contém tanto a sinopse como avisos de classificação indicativa, propiciando aos pais melhor
                    conhecimento sobre o conteúdo bem como a faixa etária mais adequada à exibição dos filmes para
                    que possam decidir, de maneira livre e desimpedida, (
                    <em>e sempre por intermédio de sua supervisão</em>
                    ),
                    se seus filhos poderão ou não assisti-los.
                    <br />
                    Já a área de notícias, artigos publicados, informativos (
                    <em>mesmo o de cunho educativo</em>
                    ), podem conter
                    informações um pouco mais sensíveis ou conter informações que crianças ou adolescentes em formação
                    ainda não estejam prontos para compreender, razão pela qual o acesso à estas áreas deve ser
                    sempre efetuado com a supervisão dos pais, para que possam decidir, de maneira livre e desimpedida,
                    se seus filhos poderão ou não ter acesso às matérias publicadas.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Os arquivos do VamuveTV são seguros?</h3>
                  <span>
                    Sim, todos os filmes são originais e não possuem nenhum tipo de vírus.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Qual a forma de execução das obras audiovisuais?</h3>
                  <span>
                    O material (
                    <em>filmes, desenhos, seriados e demais conteúdos audiovisuais</em>
                    ) são executados por demanda (
                    <em>streaming</em>
                    ) sendo esta restrita à apenas ao assinante
                    que é locador do acesso ao acervo de nossa plataforma. É necessário estar conectado
                    com a internet, necessitando uma banda mínima de 5 Mbps durante a reprodução para obter
                    uma melhor qualidade de vídeo. Recomendamos também utilizar os serviços em um computador
                    pessoal, notebook ou outro equipamento que tenha tela ampla ou que tenha uma boa resolução
                    de vídeo para que possa ter maior qualidade e melhor se entreter com o conteúdo em nossa
                    programação e acervo.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Posso fazer download (baixar) o conteúdo do site?</h3>
                  <span>
                    Não. É terminantemente proibido usar nossa plataforma para baixar (
                    <em>fazer download</em>
                    ) dos filmes e demais conteúdos de nosso site, bem como não é permitido realizar
                    engenharia reversa ou de qualquer forma efetuar a reprodução, e/ou o compartilhamento
                    de vídeos, materiais audiovisuais e demais serviços ou produtos oferecidos pela VAMUVETV
                    que sejam de uso exclusivo e restrito à área de assinantes.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Posso gravar o filme em DVD, BlueRay ou, de alguma forma reproduzir o conteúdo do site?</h3>
                  <span>
                    Não. É expressamente proibido a gravação em CDs, DVDs ou qualquer outra forma de reprodução,
                    bem como você não pode realizar engenharia reversa, programas de computador destinados à invasão
                    ou burla de nossos sistemas, bem como não pode copiar ou utilizar qualquer elemento ou conteúdo
                    de nosso website fora do ambiente de nossa plataforma. Em que pese nosso acervo audiovisual ser
                    composto, em sua maioria, por obras já caídas em domínio público, tanto nossa logomarca como alguns
                    de nossos programas e coletâneas, composições, produções, personagens ou demais criações intelectuais
                    são de propriedade exclusiva de VamuveTV, sendo vedada sua utilização sem nossa expressa autorização.
                    Além de programas que nós venhamos à produzir, também poderemos incluir tanto na grade de programação
                    como em nossa plataforma, programas criados por nossos parceiros comerciais, razão pela qual por
                    questões ligadas à segurança e direitos autorais inerentes às produções de nossos parceiros, fica
                    igualmente vedada a utilização fora de nossa plataforma.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Aparelhos de transmissão online</h3>
                  <span>
                    Dentro do ambiente doméstico ou privado, assista onde quiser, o quanto quiser e em vários aparelhos.
                    A plataforma da VamuveTV permite assistir online a todo o conteúdo em qualquer aparelho conectado à
                    Internet compatível com o website VamuveTV, como PC (Mac, Windows e Linux), Smartphone, Tablet,
                    Chromecast e Xbox One (Através do navegador EDGE). Ainda não damos suporte para Smart TV. Com a assinatura,
                    o assinante poderá utilizar nossos serviços em, no máximo, 3 (três) “telas” em sua residência.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Qual a validade da assinatura?</h3>
                  <span>
                    Caso você escolha ser um assinante, sua assinatura se refere à locação do acesso
                    ao acervo audiovisual e demais serviços disponíveis em nossa plataforma para a área
                    de assinantes, pelo período de um mês, ficando à seu exclusivo critério renová-lo
                    (ou não) para o mês subsequente ou para o próximo período do plano escolhido para
                    sua assinatura.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como é feita a cobrança no VamuveTV?</h3>
                  <span>
                    Caso você escolha ser um assinante, a cobrança é efetuada uma vez por mês no dia de sua
                    assinatura. Sem taxas de cancelamento, sem compromisso de renovação automática. Você pode
                    alterar ou mesmo cancelar online a qualquer momento se decidir não continuar a assinatura
                    para o mês subsequente.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como cancelo minha assinatura no VamuveTV?</h3>
                  <span>
                    A VamuveTV é flexível. Não há contratos nem compromissos envolvendo a assinatura de nossa
                    plataforma. Você pode cancelar a sua conta na internet de maneira fácil e rápida. Não há taxa
                    de cancelamento – você pode começar ou encerrar a sua assinatura a qualquer momento. Para cancelar
                    bastar realizar o login, clicar sobre seu nome no canto superior direito, selecionar “Meu Perfil”,
                    selecionar a opção “Cancelar assinatura” ou outra assemelhada. Sua assinatura será cancelada um dia
                    antes da cobrança do mês seguinte, podendo utilizá-la até tal data. A assinatura poderá ser cancelada,
                    também de forma automática, em caso de descontinuidade da renovação, bastando que o usuário não efetue
                    o pagamento do mês subsequente ao vencido.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como faço para assistir o conteúdo do VamuveTV em meu computador?</h3>
                  <span>
                    <ul>
                      <li>
                        Abra um navegador de internet e acesse:
                        {' '}
                        <a href="https://www.vamuvetv.com.br" target="_blank">
                          www.vamuvetv.com.br
                        </a>
                        {' '}
                        ou
                        {' '}
                        <a href="https://www.vamuvetv.com" target="_blank">
                          www.vamuvetv.com
                        </a>
                      </li>
                      <li>Entre em sua conta VamuveTV </li>
                      <li>Selecione um título para assistir.</li>
                    </ul>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Qual a velocidade de internet necessária?</h3>
                  <span>
                    <ul>
                      <li>
                        A velocidade mínima necessária é de 5 Mbps, mas recomendamos
                        uma conexão mais rápida para obter uma qualidade de vídeo melhor.
                      </li>
                      <li>
                        Mesmo sendo possível assistir online com vários níveis de largura de
                        banda, a velocidade da sua conexão com a internet pode influenciar
                        a sua experiência de visualização.
                      </li>
                      <li>
                        A VamuveTV é um serviço de transmissão online no qual filmes, programas,
                        desenhos e séries de TV são assistidos instantaneamente pela internet sem
                        necessidade de fazer download, é preciso estar conectado à internet
                        durante todo o período.
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Qual o e-mail do suporte?</h3>
                  <span>
                    A VamuveTV não atende a reclamações que são postadas em qualquer rede social,
                    apenas oferecemos suporte oficial, dentro do horário comercial
                    (
                    <em>de segundas até sextas-feiras, das 09:00 até às 18:00 horas</em>
                    ), neste email:
                    {' '}
                    <a href="mailto:contato@vamuvetv.com.br">contato@vamuvetv.com.br</a>
                    {' '}
                  </span>
                </div>

              </div>
              {/* <div className='row'>
                <div className='col-sm-12 align-items-start'>

                  </div>

              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
