import { DirectPayment } from 'pagseguro-react';
import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../../Services/token';
import Axios from '../../Axios';

const config = {
  session: 'vamoveTV',
  env: 'production',
  sender: {
    name: '',
    email: '',
    phone: {
      areaCode: '',
      number: '',
    },
    document: {
      type: '',
      value: '',
    },
  },
  items: [
    {
      id: 1,
      description: 'Assinatura mensal vamuvetv',
      quantity: 1,
      amount: 14,
    },

  ],
  creditCard: {
    maxInstallmentNoInterest: 1, // parcelas com desconto
  },
  extraAmount: 0,
  exclude: ['ONLINE_DEBIT', 'CREDIT_CARD'],
  hiddenShippingForm: true,
  hiddenBillingForm: true,
  reference: 'Teste Pagseguro React',
};
const PagSeguro = ({
  onSucess, onError, email, cep, cpf, fullname,
}) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await Axios.post('/pagseguro/getToken');
      config.session = response.data.response.message['0'];
      setLoading(false);
    }

    fetchData();

    if (email) config.sender.email = email;
    //    if(!!cep) config.billing.postalCode = cep
    if (cpf) config.sender.document.value = cpf;
    if (fullname) config.sender.name = fullname;
  }, []);

  if (!loading) {
    return (
      <div>

        <DirectPayment
          {...config}
          onError={(error) => {
            setLoading(false);
            console.error(error);
          }}
          onSubmit={(info) => {
            setLoading(true);
            const payload = {
              method: 'BOLETO',
              hash: info.sender.hash,
              name: info.sender.name,
              email: info.sender.email,
              phone: info.sender.phone.number.replace('_', ''),
              areaCode: info.sender.phone.areaCode,
              CPF: info.sender.document.type === 'CPF' ? info.sender.document.value : '',
              CNPJ: info.sender.document.type !== 'CPF' ? info.sender.document.value : '',

            };

            if (isAuthenticated()) {
              Axios.post('/pagseguro/bankSlipPayment', payload)
                .then((resp) => {
                  setLoading(false);
                  if (onSucess)onSucess(resp.data.response.message);
                })
                .catch((err) => {
                  setLoading(false);
                  if (onError)onError(err);
                });
            }
          }}
        />

      </div>
    );
  }
  return (
    <div className="btn ">
      <div className="loader " />
    </div>
  );
};

export default PagSeguro;
