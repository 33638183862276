import React from 'react';
import { useHistory, HashRouter, Link } from 'react-router-dom';
import { Form, Input, Button } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { authUser } from '../../Services/token';
import Axios from '../../Axios';
import SnackBar from '../../Components/SnackBar';
// import './login.css'

export default function Login() {
  const [loading, setLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const history = useHistory();

  function setAuth(e) {
    e.preventDefault();
    setLoading(true);
    const { login, password } = e.target;
    Axios.post('login', { emailOrUsername: login.value, pass: password.value })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const uuid = data.response.message.uuid_client;
          const type = data.response.message.type_account;
          const token = data.response.message.uuid_client;
          authUser(uuid, type, token);
          history.push('/perfil');
        } else {
          setLoading(false);
          setOpenSnack(true);
          setMessage('E-mail ou senha incorretos');
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenSnack(true);
        setMessage(`Connect Error : ${error}`);
      });
  }
  return (
    <>
      <Header />
      <div className="">
        <div id="app-show-movie" className="wrapper">
          <div className="container section single-movie">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <h2>Login</h2>
                  <div className="col-sm-12">
                    <Form
                      className="form-horizontal"
                      onSubmit={setAuth}
                      style={{ margin: 'auto !important;', padding: '3rem' }}
                    >
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="col-md-2 control-label"
                        >
                          E-mail
                        </label>
                        <div className="col-md-9">
                          <Input
                            id="email"
                            name="login"
                            type="text"
                            className="form-login"
                            placeholder="Email"
                            required
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="password"
                          className="col-md-2 control-label"
                        >
                          Senha
                        </label>
                        <div
                          className="col-md-9"
                          style={{ margin: 'auto !important;' }}
                        >
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            className="form-login"
                            placeholder="Senha"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-5">
                          <Button
                            type="submit"
                            className="btn btn-primary btn-login"
                          >
                            {!loading ? (
                              'Entrar'
                            ) : (
                              <CircularProgress color="inherit" size={20} />
                            )}
                          </Button>
                        </div>
                        <div className="col-md-6">
                          <HashRouter>
                            <Link to="/resetar-senha">
                              <p>Esqueci a Senha</p>
                            </Link>
                          </HashRouter>
                          <HashRouter>
                            <Link to="/registrar">
                              <p>Criar uma conta</p>
                            </Link>
                          </HashRouter>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackBar
        isOpen={openSnack}
        isVariant="warning"
        isMessage={message}
        isClose={() => setOpenSnack(false)}
      />
      <Footer />
    </>
  );
}
