import React, { Component } from 'react';
import { HashRouter, Link } from 'react-router-dom';
import { adminApi, siteApi } from '../../Axios';
import { isAuthenticated, logout } from '../../Services/token';
import LoginModal from '../Modals/Login';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      date: null,
      collapsed: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.getCategorys();
    this.getActualData();
  }

  getCategorys = () => {
    adminApi.get('categorias')
      .then((resp) => {
        if (resp.data.status) {
          this.setState({ categories: resp.data.response.message });
        }
      })
      .catch((error) => {
        alert(`Connect Error : ${error} DOI`);
      });
  }

  getActualData = () => {
    const data = new Date();

    const dia = data.getDate().toString();

    const diaF = dia.length === 1 ? `0${dia}` : dia;

    const mes = (data.getMonth() + 1).toString();

    const mesF = mes.length === 1 ? `0${mes}` : mes;

    const anoF = data.getFullYear();

    this.setState({
      date: `${diaF}/${mesF}/${anoF}`,
    });
  }

  setLogout = () => {
    siteApi.get('/logout')
      .then((resp) => {
        logout();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const {
      categories,
      collapsed,
      date,
      isMobile,
    } = this.state;
    return (
      <>
        <div id="app" className="">
          <div className="navbar" role="navigation">
            <div className="">
              <div className="navbar-header">
                <a href="/" className="logo" title="VamuVeTV">
                  <img
                    style={{ height: '54px' }}
                    src="https://vamuvetv.com.br/assets/images/default/logo.png"
                    alt="VamuVeTV"
                  />
                </a>
                <button
                  type="button"
                  onClick={() => this.setState(oldState => ({ collapsed: !oldState.collapsed, isMobile: true }))}
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar top-bar" />
                  <span className="icon-bar middle-bar" />
                  <span className="icon-bar bottom-bar" />
                </button>
              </div>
              <div className={`navbar-collapse ${collapsed ? 'collapse' : ''}`}>
                <ul id="menu-primary" className="nav navbar-nav">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">Categorias</a>
                    <ul
                      className="dropdown-menu"
                      style={{
                        maxHeight: 500,
                        width: 300,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                      }}
                    >
                      {categories.map(category => (
                        <li key={category.id_ct}>
                          <HashRouter>
                            <Link
                              to={`/categoria/${category.url_name}`}
                              replace
                            >
                              {category.category}
                            </Link>
                          </HashRouter>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <HashRouter>
                      <Link to="/programacao">
                        Programação
                        <span style={{ fontSize: '0.5rem' }}>
                          <br />
                          {date}
                        </span>
                      </Link>
                    </HashRouter>
                  </li>
                  <li>
                    <HashRouter>
                      <Link to="/noticias">
                        Notícias
                        <span>
                          <br />
                        </span>
                      </Link>
                    </HashRouter>
                  </li>
                  {
                    !!isMobile && (
                      !isAuthenticated() ? (
                        <li>
                          <Link
                            style={{
                              fontSize: '16px ',
                              fontWeight: 'normal ',
                              lineHeight: '.8em ',
                              display: 'inline-block ',
                              padding: '20px 24px ',
                              letterSpacing: '3px ',
                              textTransform: 'uppercase ',
                              color: '#fff ',
                            }}
                            to="/login"
                          >
                            Entrar
                          </Link>
                        </li>
                      ) : (
                        <>
                          <li>
                            <Link
                              style={{
                                fontSize: '16px ',
                                fontWeight: 'normal ',
                                lineHeight: '.8em ',
                                display: 'inline-block ',
                                padding: '20px 24px ',
                                letterSpacing: '3px ',
                                textTransform: 'uppercase ',
                                color: '#fff ',
                              }}
                              to="/perfil"
                            >
                              Perfil
                            </Link>
                          </li>
                          <li>
                            <button
                              type="submit"
                              style={{
                                fontSize: '16px ',
                                fontWeight: 'normal ',
                                lineHeight: '.8em ',
                                display: 'inline-block ',
                                padding: '20px 24px ',
                                letterSpacing: '3px ',
                                textTransform: 'uppercase ',
                                color: '#fff ',
                              }}
                              onClick={() => this.setLogout()}
                            >
                              Sair
                            </button>

                          </li>
                        </>
                      )
                    )
                  }
                </ul>
                {
                  !isMobile && (
                    !isAuthenticated() ? (
                      <li>
                        <Link
                          className="btn btn-default pull-right"
                          style={{ marginTop: 5, color: '#FFFFFF' }}
                          to="/login"
                        >
                          Entrar
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <button
                          type="submit"
                          className="btn btn-default pull-right"
                          onClick={() => this.setLogout()}
                          style={{ marginLeft: '0.5rem' }}
                        >
                          Sair
                        </button>
                        <Link to="/perfil">
                          <button
                            type="submit"
                            className="btn btn-default pull-right"
                            style={{ marginLeft: '0.5rem' }}
                          >
                            Perfil
                          </button>
                        </Link>
                      </li>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
