import React, { useState } from 'react'
import { HashRouter, Link, Redirect } from 'react-router-dom'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { isAuthenticated } from '../../Services/token'

export default function Signature () {
  const [status, changeStatus] = useState(false)
  
  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  function checkAuth () {
    if (!isAuthenticated()) {
      changeStatus('notlogged')
    } else {
      changeStatus('logged')
    }
  }

  return (
    <>
      <Header />
      <div className=''>
        <div id='app-show-movie' cclassName='wrapper'>
          <div className='container section single-movie'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='row'>
                  <h2>Torne-se um assinante VamuveTV Hoje!!</h2>
                  <div className='col-sm-12'>
                    <p style={{textAlign: 'justify', maxWidth: '100%'}}>
                      O melhor do cinema clássico diretamente para você. <br />
                      Aqui você encontrará verdadeiras reliquias dos tempos
                      dourados da TV e do cinema, em uma plataforma inovadora
                      onde você poderá assistir a quantos filmes quiser, quando
                      quiser e onde quiser de uma maneira agradável. Ao se
                      tornar um assinante, você terá todas as vantagens do nosso
                      sistema, dispondo de um acervo imenso de filmes, produções
                      próprias, desenhos e uma grade de programação única.
                      <br />O assinante mensal VamuveTV terá acesso a todo nosso
                      acervo para que possa ter a sensação de um verdadeiro
                      retorno ao passado.
                    </p>
                  </div>
                </div>
                <br />

                <button className='btn btn-default' onClick={checkAuth}>
                  <i className='fas fa-shopping-cart' />
                  <span>Assine Agora</span>
                </button>

                <br />
                <br />
                <br />
              
              </div>
            </div>
          </div>
        </div>
      </div>
      {status === 'logged' && (
        <HashRouter>
          <Redirect to={'/pagamento/assinatura'} />
        </HashRouter>
      )}
      {status === 'notlogged' && (
        <HashRouter>
          <Redirect to={'/login'} />
        </HashRouter>
      )}
      <Footer />
    </>
  )
}
