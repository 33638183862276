import React from 'react';
import {
  HashRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { isAuthenticated, getAccountType } from './Services/token';
import Main from './Pages/Main/index';
import News from './Pages/News';
import NewsDetail from './Pages/News/NewsDetail';
import Category from './Components/Category';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Payment from './Pages/Payment/index';
import Movie from './Components/ViewMovie';
import Serie from './Components/ViewSerie';
import Signature from './Pages/Signature';
import Programation from './Pages/Programation';
import Screen from './Components/Screen';
import Actor from './Components/ViewActor';
import MovieLocation from './Components/Screen/Movie';
import About from './Pages/About';
import Faq from './Pages/Faq';
import Privacity from './Pages/Privacity';
import Profile from './Pages/Profile';
import Partnerships from './Pages/Partnerships';
import Thanks from './Pages/ThanksPage';
import ForgotPassword from './Pages/ForgotPassword';

import SetNewPassword from './Pages/SetNewPassword';
import TermsOfUse from './Pages/TermsOfUse';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )}
  />
);

const MemberRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => isAuthenticated() ? (
      getAccountType() === 'Member' ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )}
  />
);

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/categoria/:name" component={Category} />
      <Route exact path="/filme/:id" component={Movie} />
      <Route exact path="/serie/:id" component={Serie} />
      <Route exact path="/blog" component={News} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/registrar" component={Register} />
      <Route exact path="/resetar-senha" component={ForgotPassword} />
      <Route exact path="/atualizar-senha/:id" component={SetNewPassword} />
      <Route exact path="/noticias" component={News} />
      <Route exact path="/noticias/:id" component={NewsDetail} />
      <Route exact path="/assinar" component={Signature} />
      <Route exact path="/programacao" component={Programation} />
      <Route exact path="/diretor/:id" component={Actor} />
      <Route exact path="/sobre" component={About} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/privacidade" component={Privacity} />
      <Route exact path="/termos-de-uso" component={TermsOfUse} />
      <Route exact path="/parcerias" component={Partnerships} />
      <PrivateRoute exact path="/obrigado" component={Thanks} />
      <PrivateRoute exact path="/perfil" component={Profile} />
      <PrivateRoute exact path="/pagamento/:type" component={Payment} />
      <PrivateRoute
        exact
        path="/reproduzir/filme/:id"
        component={MovieLocation}
      />
      <MemberRoute exact path="/streaming" component={Screen} />
      <Route path="*" component={() => <h1>Página não Encontrada!</h1>} />
    </Switch>
  </HashRouter>
);

export default Routes;
