import React from 'react';
import { Link } from 'react-router-dom';

export default function Tidings({ id, cover, title, date, text }) {
  return (
    <>
      <div className="newsContainer" style={{marginTop : '1rem'}}>
        <div className="coverContainer">
          <img src={cover} alt="Capa noticia" />
        </div>
        <div className="newsInfoContainer" style={{ borderBottom: '1px solid #ccc' }}>
          <Link to={`/noticias/${id}`} className="titleNews">{title}</Link>
          <h4 className="no-underline">{date}</h4>
          <div className="newsResume" dangerouslySetInnerHTML={{__html:text}} />
        </div>
      </div>
    </>
  );
}
