import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Routes from './routes';

// import './App.css';

// import './index.css';
import './Assets/css/app.css';
import './Assets/css/all.css';
import './Assets/css/style.css';
import './Assets/css/login.css';
import './Assets/css/payment.css';
import './Assets/css/register.css';

import './Assets/css/styles.css';
import './Assets/css/hero.css';
import './Assets/css/main.css';

import './Assets/css/stylus.css';
import './Assets/css/stylus-og.css';

const App = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>VamuveTV</title>
    </Helmet>
    <Routes />
  </>
);

export default App;
