import React, { Component } from 'react'
import PaypalExpressBtn from 'react-paypal-express-checkout'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Purchase from '../../Components/PurchaseData'
import Axios from '../../Axios'
import SnackBar from '../../Components/SnackBar'
import { Redirect } from 'react-router-dom'
import PagSeguro from '../../Components/Pagseguro'
// import './payment.css'

export default class Payment extends Component {
  constructor ({ match }) {
    super()
    let value = null
    if (match.params.type === 'assinatura') {
      value = 19.9
    }

    this.state = {
      value: value,
      snackbar: false,
      snackVariant: null,
      snackMessage: null,
      redirect: false,
      showPagSeguroModal:false,
      showSuccessPage:false,
      successInfo:null,
      profileEmail:"",
      profileCPF:"",
      profileCEP:"",
      profileFullname:""
    }
  }

  componentDidMount () {
    window.scroll(0, 0)

    Axios.get('/usuario')
    .then(resp => {
      const data = resp.data
      if (data.status) {
         const {email,cpf,cep,fullname} = data.response.message

          
         
            this.setState({
              profileCEP:cep,
              profileCPF:cpf,
              profileEmail:email,
              profileFullname:fullname
            })
          
         
        }
      })
  }

  render () {
    const onSuccess = payment => {
      if (payment.paid) {
        Axios.post('/usuario/assinar', {
          token: payment.paymentToken,
          value: null,
          status: null
        })
          .then(resp => {
            const data = resp.data
            if (data.status) {
              this.setState({
                redirect: true
              })
            } else {
              this.setState({
                snackbar: true,
                snackMessage:
                  'Houve um erro desconhecido, entre em contato com nosso suporte',
                snackVariant: 'error'
              })
            }
          })
          .catch(error => {
            this.setState({
              snackbar: true,
              snackMessage: `Erro de conexão : ${error}`,
              snackVariant: 'error'
            })
          })
      }
    }

    return (
      <>
        <Header />
        <div className=''>
          <div className='wrapper' style={{ padding: '2rem' }}>
            <div className='container section col-md-12'>
              <div className='row col-md-12' style={{ margin: '50px auto;' }}>
                <Purchase
                  type={'Assinatura'}
                  value='14,90'
                  duration='1 mês'
                  plan='Plano Básico'
                  access='Todos os recursos VamuveTv'
                />

               {
                 !this.state.showPagSeguroModal && !this.state.showSuccessPage && !!this.state.profileFullname ? (
                  <button   className='btn btn-default' onClick={() => this.setState({showPagSeguroModal:true})}>
                    Assine Agora
                  </button> 

                 ) : !this.state.profileFullname && (
                  <div className="btn "  >
                    <div className="loader "/>
                  </div>

                 )
               }

                {
                  !!this.state.showPagSeguroModal && !this.state.showSuccessPage && (
                    <PagSeguro {...{fullname:this.state.profileFullname,cep:this.state.profileCEP,cpf:this.state.profileCPF,email:this.state.profileEmail}} onSucess={(response) => {
                      this.setState({showSuccessPage:true,successInfo:response,showPagSeguroModal:false})
                    }}/>
                  )
                }

                {
                  !!this.state.showSuccessPage && !this.state.showPagSeguroModal &&  (
                      <div  >
                          <h2>Quase Lá!</h2>
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                          <span style={{marginTop:30}}>Falta pouco para você virar um assinante, sua assinatura será efetivada em até 72 horas após o pagamento do boleto.</span>
                          <span style={{marginTop:20}}>Link para pagar o boleto</span>

                          <a href={this.state.successInfo.paymentLink} target={'__blank'} className='btn btn-default' style={{marginTop:40}}>
                              Ver Boleto
                          </a>
                          </div>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <SnackBar
          isOpen={this.state.snackbar}
          isVariant={this.state.snackVariant}
          isMessage={this.state.snackMessage}
          isClose={() => this.setState({ snackbar: false })}
        />
        {this.state.redirect && <Redirect to='/obrigado' />}
        <Footer />
      </>
    )
  }
}
