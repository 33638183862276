export const TOKEN_KEY = null;
export const ACCOUNT_TYPE = 'free';
export const SUBSCRIBE_TOKEN = null;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getAccountType = () => localStorage.getItem(ACCOUNT_TYPE);
export const getUUID = () => localStorage.getItem(TOKEN_KEY);

export const authUser = (token, type, subscribe) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(ACCOUNT_TYPE, type);
  localStorage.setItem(SUBSCRIBE_TOKEN, subscribe);
};
export const logout = () => {
  localStorage.clear();
  window.location.reload();
};
