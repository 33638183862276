import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'

export default function Thanks ({ motive }) {
  React.useEffect(() => {
    localStorage.setItem('ACCOUNT_TYPE', 'Member')
  })

  return (
    <>
      <Header />
      <div className=''>
        <div id='app-show-movie' cclassName='wrapper'>
          <div className='container section single-movie'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='row'>
                  <h2>Muito obrigado por se juntar a nós</h2>
                  <div className='col-sm-12'>
                    <p>
                      Obrigado por se tornar assinante vamuvetv, nós somos
                      honrados por poder oferecer a você o melhor do cinema
                      clássico, <br />
                      sem anúncios ou propagandas.
                    </p>
                  </div>
                </div>
                <br />
                <Link to='/'>
                  <button className='btn btn-default'>
                    <span>Clique aqui e aproveite agora o vamuvetv!!</span>
                  </button>
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
