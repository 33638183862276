import React, { Component,useState } from 'react'
import { Input, Button } from 'reactstrap'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Axios from '../../Axios'
import Loading from '../../Components/Loading'
import SnackBar from '../../Components/SnackBar'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    async function sendPasswordResetRequest(){
        if(!!email){
            const res = await Axios.post("passwords/recoveryPasswordEmailSend",{email})
            if(!!res.data.status )alert("Enviamos um código de redefinição de senha para o seu email.")
            else alert(res.data.response.message)
        }else alert("Por favor, preencha seu email")
    }

    return (
        <>
            <Header/>
                <div id='app-show-movie' className='container' style={{flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:70,height:'53vh'}}>
                    <div style={{borderRadius:20,padding:20,backgroundColor:'#FFFFFF',display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'column'}}>
                        <Input
                            id='email'
                            name='login'
                            type='text'
                            className='form-login'
                            placeholder='Email'
                            required
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Button
                            onClick={() => sendPasswordResetRequest()}
                            className='btn btn-primary btn-login'
                            style={{
                                marginLeft:0,
                                 marginTop:30,
                                alignSelf:'center'        
                            }}
                          >
                            Enviar
                          </Button>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default ForgotPassword