import React, { Component,useState,useEffect } from 'react'
import { Input, Button } from 'reactstrap'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Axios from '../../Axios'
import Loading from '../../Components/Loading'
import SnackBar from '../../Components/SnackBar'
import { Link } from 'react-router-dom'

const SetNewPassword = ({match}) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [requestId, setRequestId] = useState(null);
    async function sendPasswordUpdate(){
        if(!password || !confirmPassword) return alert("Por favor, preencha todos os campos")
        if(password !== confirmPassword ) return alert("As senhas não são iguais")
        const res = await Axios.post("passwords/recoveryPasswordChange",{password,uuid:requestId})
        if(!!res.data.status )alert("Senha alterada com sucesso!")
        else alert(res.data.response.message)
        window.location = "/"
        
    }

    useEffect(() => {

        const requestId = match.params.id
        if(!requestId) {
            alert("Solicitação não encontrada")
            window.location = "/"
        }else 
        setRequestId(requestId)

        return () => {
            
        };
    }, [])

    return (
        <>
            <Header/>
                <div id='app-show-movie' className='container' style={{flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:70,height:'53vh'}}>
                    <div style={{borderRadius:20,padding:20,backgroundColor:'#FFFFFF',display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'column'}}>
                        <Input
                            id='email'
                            name='login'
                            type='password'
                            className='form-login'
                            placeholder='Senha'
                            required
                            autoFocus
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Input
                            id='email2'
                            name='login'
                            type='password'
                            className='form-login'
                            placeholder='Confirmar Senha'
                            required
                            autoFocus
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <Button
                            onClick={() => sendPasswordUpdate()}
                            className='btn btn-primary btn-login'
                            style={{
                                marginLeft:0,
                                 marginTop:30,
                                alignSelf:'center'        
                            }}
                          >
                            Enviar
                          </Button>
                    </div>
                </div>
            <Footer />
        </>
    )
}

export default SetNewPassword