import React from 'react'
import { HashRouter, Link } from 'react-router-dom'
import Blog from '../Blog'
import Axios from '../../Axios'
import Header from '../Header'
import Footer from '../Footer'
import { isAuthenticated, getAccountType } from '../../Services/token'
import Loading from '../Loading'

export default class Actor extends React.Component {
  state = {
    actor: null,
    loading: false
  }

  componentDidMount () {
    this.setState({ loading: true })
    Axios.get(`/actor/getActorBySlug/${this.props.match.params.id}`)
      .then(resp => {
        const { data } = resp
        if (data.status) {
          this.setState({
            actor: data.response.message,
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  convertSinopsis () {
    const htmlToText = require('html-to-text')
    const text = htmlToText.fromString(this.state.actor.biograpy, {
      wordwrap: 130
    })
    return text
  }

  converBrtDate = () => {
    if (this.state.actor.brt_date === null) {
      return ''
    }
    return new Date(this.state.actor.brt_date)
      .toISOString()
      .substr(0, 10)
      .split('-')
      .reverse()
      .join('/')
  }

  convertDate = () => {
    if (this.state.actor.dead_date === null) {
      return ''
    }
    return new Date(this.state.actor.dead_date)
      .toISOString()
      .substr(0, 10)
      .split('-')
      .reverse()
      .join('/')
  }

  makeGalleryPictures = () => {
    const pictures = this.state.serie.gallery.split(', ')
    return pictures.map((picture, key) => (
      <div className='movie-slide'>
        <img
          src={picture}
          alt={`gallery ${key}`}
          style={{ maxHeight: '150px' }}
        />
      </div>
    ))
  }

  render () {
    return (
      <>
        <Header />
        {this.state.actor && (
          <div className=''>
            <div id='app-show-actor' className='wrapper'>
              <div className='container section single-actor'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>Informações do diretor</h2>
                    <div className='row'>
                      <div className='col-sm-5'>
                        <img
                          src={this.state.actor.profile_image}
                          alt=''
                          className='picture'
                        />
                      </div>
                      <div className='col-lg-7'>
                        <h3 className='no-underline'>
                          {this.state.actor.fullname}
                        </h3>
                        <text dangerouslySetInnerHTML={{__html:this.state.actor.biography}}></text>
                        <ul className='movie-info'>
                          
                          <li>
                            <i>Data de nascimento:</i>
                            {this.state.actor.date_birth}
                          </li>
                          <li>
                            <i>Data de Falecimento:</i>
                            {this.state.actor.date_death}
                          </li>
                          <li>
                            <i>Local de nascimento:</i>
                            {this.state.actor.birth_cidade}
                          </li>
                          <li>
                            <i>Local de Falecimento: </i>
                            {this.state.actor.death_estado}
                          </li>
                          <li>
                              <i>Filmes:</i>
                              <div style={{display:'flex',flexDirection:'column',marginLeft:15}}>
                                {this.state.actor.movies.map(x =>  
                                <a href={`#/filme/${x.slug}`}>
                                  {x.mv_title}
                                </a>)}
                              </div>
                              
                            </li>
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {() => isAuthenticated() && <Blog />}
            </div>
          </div>
        )}
        <Footer />
        <Loading
          open={this.state.loading}
          text='Estamos preparando os dados do ator, aguarde...'
          theme='white'
        />
      </>
    )
  }
}
