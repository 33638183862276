import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function Partnerships() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div>
        <div className="">
          <div id="app-show-movie" className="wrapper">
            <div className="container section single-movie">
              <h2>Parcerias e Anúncios</h2>
              <div className="faq-item-wrapper">
                <div className="faq-item">
                  <h3>Como anunciar na VamuveTV?</h3>
                  <span>
                    Para muitas pessoas, saber como anunciar em sites ou plataformas digitais ainda é uma
                    grande barreira para ingressar de vez no universo do marketing online. O problema é que
                    a falta desta informação pode estar fazendo com que elas percam diversas oportunidades de
                    negócios. É inegável que as redes sociais e plataformas online são, atualmente, uma das
                    maiores mídias do mundo, e sem sombra de dúvidas, anunciar na VamuveTV é uma ótima oportunidade
                    para geração de tráfego para qualquer tipo de site, além de uma maneira prática de colocar
                    sua marca e produtos em evidência.
                    <br />
                    A VamuveTV é um serviço inovador na área de entretenimento, que envolve o acesso à informação
                    de material audiovisual, notícias de cinema, TV, artistas, informação em geral sobre a área de
                    entretenimento clássico, bem como a locação de filmes online, além de veiculação de programas
                    próprios na área de entretenimento, permitindo aos clientes assistir a uma ampla variedade de filmes,
                    desenhos, documentários, programas de televisão e séries clássicas da TV de forma simples e sem sair de casa.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Quem pode anunciar?</h3>
                  <span>
                    Qualquer pessoa física ou jurídica que queira divulgar sua marca, seus produtos, seus serviços, pode anunciar conosco.
                    <br />
                    Dentre os tipos de anúncios que poderão ser veiculados, destacam-se:
                    <br />
                    <br />
                    <ul>
                      <li>
                        Vídeos comerciais que serão exibidos entre as veiculações de nosso acervo de filmes,
                        desenhos, series e programas veiculados na plataforma da VamuveTV
                      </li>
                      <li>
                        Banners da marca, produtos ou serviços do anunciante, por onde o nosso usuário poderá
                        clicar na imagem e ser direcionado ao produto, site ou rede social do anunciante para
                        melhor se inteirar ou efetivar sua compra que está sendo anunciada;
                      </li>
                      <li>
                        Divulgação de produtos, serviços ou da marca do anunciante na forma de patrocínio,
                        apresentado durante a exibição dos programas a serem veiculados na VamuveTV
                      </li>
                    </ul>
                    <br />
                    Tanto em relação aos anúncios como patrocínio, os anunciantes ou patrocinadores deverão encaminhar
                    seu material para nossa área de marketing para que seja especificada a forma como o anúncio será veiculado,
                    tamanhos, formato da mídia e demais especificações técnicas relativas ao produto ou serviço
                    que se pretenda anunciar.
                    <br />
                    <br />
                    Para maiores informações, entre em contato com nossa área e marketing no email:
                    {' '}
                    <a href="mailto:marketing@vamuvetv.com.br">marketing@vamuvetv.com.br</a>
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Tenho um programa que gostaria de ser veiculado, como faço para apresentá-lo?</h3>
                  <span>
                    Se você é apresentador, produtor, empresário e tem algum material interessante em vídeo que gostaria de
                    veicular conosco como, por exemplo, projeto de programa de televisão ou de redes sociais, entre em contato
                    com nossa área de marketing no email:
                    {' '}
                    <a href="mailto:marketing@vamuvetv.com.br">marketing@vamuvetv.com.br</a>
                    {' '}
                    e nos informe brevemente que tipo de trabalho
                    possui, qual o público alvo e o formato de seu programa (
                    <em>
                      humor, noticiário, seriado, animação, documentário,
                      quadro de fofocas, viagens, assuntos de cinema, etc
                    </em>
                    ) para que possamos entrar em contato e vermos a viabilidade
                    de sua veiculação. Se o seu projeto for aprovado, poderemos ajudar a divulgar seu trabalho para fazermos uma
                    parceria de sucesso.
                  </span>
                </div>
                <div className="faq-item">
                  <h3>Como posso me tornar um parceiro?</h3>
                  <span>
                    <ul>
                      <li>
                        Você que tem um estúdio de cinema, TV ou de conteúdo de internet, é produtor ou distribuidor,
                        essa poderá ser sua oportunidade de aumentar suas vendas de DVDs, BlueRays ou divulgar seu material
                      </li>
                      <li>
                        Se você tem interesse em se tornar um parceiro VamuveTV, entre em contato com nossa área de marketing
                        no email:
                        {' '}
                        <a href="mailto:marketing@vamuvetv.com.br">marketing@vamuvetv.com.br</a>
                        {' '}
                        e nos encaminhe detalhes sobre a parceria que gostaria de realizar, para verificarmos se há viabilidade
                        de conseguirmos realizar uma parceria de sucesso.
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
