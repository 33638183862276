import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Axios from '../../Axios';
import Loading from '../../Components/Loading';
import SnackBar from '../../Components/SnackBar';
// import './register.css'

export default class Register extends Component {
  state = {
    name: null,
    email: null,
    phone: null,
    userName: null,
    cep: null,
    adress: null,
    neighborhood: null,
    city: null,
    state: null,
    cpf: null,
    password: null,
    passwordConfirm: null,
    termsOfUse: false,
    error: null,
    loading: false,
    text: null,
    openSnack: false,
    message: null,
    variant: 'success',
  }

  getAdrressByCep = (e) => {
    const cep = e.target.value;
    if (cep.length >= 8) {
      this.setState({
        loading: true,
        text: 'Estamos buscando seu endereço...',
      });
      Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((resp) => {
          const { data } = resp;
          this.setState({
            adress: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            cep,
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleSignUp = (e) => {
    e.preventDefault();

    if (this.state.name.split(/\W+/).length === 1) {
      return this.setState({
        error: 'Preencha seu nome completo',

      });
    }
    if (this.state.password !== this.state.passwordConfirm) {
      return this.setState({
        error: 'As senhas não conferem',
      });
    }
    if (!this.state.termsOfUse) {
      return this.setState({
        error: 'Aceite os termos de uso',
      });
    }

    this.setState({
      loading: true,
      error: null,
      text: 'Estamos registrando sua conta...',
    });
    Axios.post('/usuario/inserir', {
      fullname: this.state.name,
      username: this.state.userName,
      email: this.state.email,
      pass: this.state.password,
      adress: this.state.adress,
      city: this.state.city,
      neighborhood: this.state.neighborhood,
      country: this.state.state,
      cep: this.state.cep,
      cpf: this.state.cpf,
      phone: this.state.phone,
    })
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            openSnack: true,
            message:
                'Conta Registrada com sucesso, clique em entrar para fazer login',
            loading: false,
            variant: 'success',
          });
          window.location = '/#/login';
        } else {
          this.setState({
            loading: false,
            openSnack: true,
            message: 'Essa conta já está registrada em nosso sistema',
            variant: 'error',
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          openSnack: true,
          message: `Houve um erro em nossos servidores : ${error}`,
          variant: 'error',
        });
      });
  }

  render() {
    const { error } = this.state;
    return (
      <>
        <Header />
        <div className="">
          <div className="wrapper">
            <div className="container section remove-top-padding">
              <div className="row" style={{ margin: '50px auto' }}>
                <div className="col-sm-12" id="">
                  <h2> Cadastre-se no VamuveTv.</h2>
                  <p style={{ 'padding-left': '2%', 'max-width': '100%', 'text-align': 'justify' }}>
                    Ao se cadastrar você tem acesso a nossos filmes e assinando
                    nossos planos você tem acesso a nosso servico de Cinema e
                    aluguel de filmes, além de claro, poder interarir no seu
                    filme favorito através do nosso blog. Clique em
                    {' '}
                    <Link to="/sobre">saber mais</Link>
                    {' '}
                    para entender as
                    vantagens de ser assinante VamuveTV.
                  </p>

                  <div>
                    <div id="error" className="col-md-12">
                      {' '}
                      {error && <p style={{ color: 'red', marginTop: 10, marginBottom: 10 }}>{error}</p>}
                      {' '}
                    </div>
                  </div>
                  <form onSubmit={this.handleSignUp} style={{ marginTop: 20 }}>
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="completeName"
                        className="col-md-9 control-label"
                      >
                        Nome Completo
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="completeName"
                          name="completeName"
                          type="text"
                          placeholder="Digite seu nome completo"
                          onChange={e => this.setState({ name: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email" className="col-md-9 control-label">
                        E-mail
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Digite seu e-mail"
                          onChange={e => this.setState({ email: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phone" className="col-md-9 control-label">
                        Telefone para Contato
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="phone"
                          type="text"
                          className="form-register"
                          name="phone"
                          placeholder="Digite seu nome de usuário"
                          onChange={e => this.setState({ phone: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="name" className="col-md-9 control-label">
                        Nome de Login
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="userName"
                          name="userName"
                          type="text"
                          placeholder="Digite seu nome de usuário"
                          onChange={e => this.setState({ userName: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="cep" className="col-md-9 control-label">
                        CEP
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="cep"
                          name="cep"
                          type="number"
                          placeholder="Sem pontos ou tracos"
                          onChange={this.getAdrressByCep}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label
                        htmlFor="adress"
                        className="col-md-9 control-label"
                      >
                        Endereco
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="adress"
                          name="adress"
                          type="text"
                          value={this.state.adress}
                          placeholder="Digite seu endereco"
                          onChange={e => this.setState({ adress: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label
                        htmlFor="neighborhood"
                        className="col-md-9 control-label"
                      >
                        Bairro
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="neighborhood"
                          name="neighborhood"
                          type="text"
                          value={this.state.neighborhood}
                          placeholder="Digite seu bairro"
                          onChange={e => this.setState({ neighborhood: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="city" className="col-md-9 control-label">
                        Cidade
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="city"
                          name="city"
                          type="text"
                          value={this.state.city}
                          placeholder="Cidade"
                          onChange={e => this.setState({ city: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="state" className="col-md-9 control-label">
                        Estado
                      </label>
                      <div className="col-md-12">
                        <Input
                          className="form-register"
                          id="state"
                          name="state"
                          type="text"
                          value={this.state.state}
                          placeholder="UF"
                          onChange={e => this.setState({ state: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label
                        htmlFor="referencia"
                        className="col-md-2 control-label"
                      >
                        Complemento
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="referencia"
                          type="number"
                          className="form-register"
                          name="referencia"
                          placeholder="Referência"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="cpf" className="col-md-2 control-label">
                        CPF
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="cpf"
                          type="number"
                          className="form-register"
                          name="cpf"
                          placeholder="Sem pontos ou tracos"
                          onChange={e => this.setState({ cpf: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="age" className="col-md-2 control-label">
                        Idade
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="age"
                          type="number"
                          className="form-register"
                          name="age"
                          placeholder="Idade"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="password"
                        className="col-md-2 control-label"
                      >
                        Senha
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="password"
                          type="password"
                          className="form-register"
                          name="pass"
                          placeholder="Digite sua senha"
                          required
                          onChange={e => this.setState({ password: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label
                        htmlFor="passwordConfirm"
                        className="col-md-12 control-label"
                      >
                        Confirmar Senha
                      </label>
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="passwordConfirm"
                          type="password"
                          className="form-register"
                          name="passwordConfirm"
                          placeholder="Confirme sua senha"
                          required
                          onChange={e => this.setState({ passwordConfirm: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <div
                        className="col-md-12"
                        style={{ margin: 'auto !important;' }}
                      >
                        <Input
                          id="termsOfUse"
                          type="checkbox"
                          className="checkTerms"
                          name="termsOfUse"
                          required
                          onChange={e => this.setState({ termsOfUse: e.target.value })}
                        />
                        <label htmlFor="termsOfUse" className="labelTerms">
                          Li e aceito a
                          {' '}
                          <Link to="/privacidade" target="_blank">Política de Privacidade</Link>
                          {' e '}
                          <Link to="/termos-de-uso" target="_blank">Termos de uso</Link>
                          .
                        </label>
                      </div>

                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="form-group">
                      <div className="col-md-12">
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-btn"
                          style={{ width: '100% !important;' }}
                        >
                          Cadastrar
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loading open={this.state.loading} text={this.state.text} />
        {this.state.openSnack && (
          <SnackBar
            isOpen={this.state.openSnack}
            isVariant={this.state.variant}
            isMessage={this.state.message}
            isClose={() => this.setState({ openSnack: false })}
          />
        )}
        <Footer />
      </>
    );
  }
}
