import React from 'react'
import { Player, BigPlayButton, LoadingSpinner, ControlBar } from 'video-react'
import Header from '../Header'
import Footer from '../Footer'
import '../../../node_modules/video-react/dist/video-react.css'
import Axios from '../../Axios'

export default function Screen () {
  const [actualTime, setActualTime] = React.useState(0)
  const [finishTime, setFinishTime] = React.useState(0)
  const [url, setFilmUrl] = React.useState(null)
  const [film, setFilm] = React.useState(false)

  function getActualMovie () {
    Axios.get('/telao')
      .then(resp => {
        const { data } = resp
        if (data.status) {
          setFilm(true)
          setActualTime(data.response.message.timeactual)
          setFinishTime(data.response.message.timetofinish)
          setFilmUrl(data.response.message.movie_url)
          if (data.response.message.timetofinish === 2) {
            window.location.reload()
          }
        } else {
          setFilm(false)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  React.useEffect(
    () => {
      getActualMovie()
    },
    [finishTime]
  )

  return (
    <>
      <Header />
      {film ? (
        <div className=''>
          <div id='app-show-serie' className='wrapper'>
            <div id='hero' className='carousel slide carousel-fade'>
              <div className='carousel-inner'>
                {actualTime !== 0 && (
                  <Player autoPlay startTime={actualTime} src={url}>
                    <BigPlayButton position='center' />
                    <LoadingSpinner />
                    <ControlBar disableDefaultControls />
                  </Player>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 style={{ fontSize: '20px', marginTop: '60px' }}>
          Não existe nenhum filme programado
        </h1>
      )}
      <Footer />
    </>
  )
}
