import React from 'react';
import { HashRouter, Link } from 'react-router-dom';
import Blog from '../Blog';
import Axios from '../../Axios';
import Header from '../Header';
import Footer from '../Footer';
import { isAuthenticated, getAccountType } from '../../Services/token';
import PictureModal from '../Modals/Picture';
import Loading from '../Loading';

export default class Movie extends React.Component {
  state = {
    movie: null,
    pictureView: false,
    loading: false,
    located: false,
  }

  componentDidMount() {
    this.setState({ loading: true });
    Axios.get(`movie/getMovie/${this.props.match.params.id}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          this.setState({
            movie: data.response.message,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        alert(`Erro de conexão : ${error} TRES`);
      });
  }

  convertSinopsis() {
    const htmlToText = require('html-to-text');
    const text = htmlToText.fromString(this.state.movie.mv_sinopse, {
      wordwrap: 130,
    });
    return text;
  }

  splitDirectors = (directors) => {
    if (directors) {
      return directors.map((name, i) => (
        <HashRouter>
          <Link to={`/diretor/${this.transformNameinSlug(name.name_director)}`}>
            {directors.length === 1
              ? name.name_director
              : i + 1 === directors.length
                ? name.name_director
                : `${name.name_director}, `}
          </Link>
        </HashRouter>
      ));
    }
  }

  transformNameinSlug = (name) => {
    const split = name.split(' ');
    const fix = split.join('-');
    return fix.toLowerCase();
  }

  convertDate = () => new Date(this.state.movie.release_date)
    .toISOString()
    .substr(0, 10)
    .split('-')
    .reverse()
    .join('/')

  makeGalleryPictures = () => {
    const pictures = this.state.movie.mv_gallery.split(', ');
    return pictures.map((picture, key) => (
      <div className="movie-slide">
        <img
          src={picture}
          alt={`banner ${key}`}
          style={{ width: '141px', height: '141px' }}
          onClick={() => this.setState({ pictureView: true })}
        />
      </div>
    ));
  }

  buttonsType = () => (
    <>
      {
              this.state.movie.commingsoon === '1' ? (
                <button disabled style={{ backgroundColor: '#e76115' }} className="btn btn-default">
                  <span> EM BREVE </span>
                </button>
              ) : (
                <HashRouter>
                  <Link
                    to={`${
                      isAuthenticated()
                        ? `/reproduzir/filme/${this.state.movie.slug}`
                        : '/login'
                    }`}
                    className="btn btn-default"
                    style={{ backgroundColor: '#e76115' }}
                    disabled={this.state.movie.commingsoon === '1'}
                  >

                    {/*  */}

                    <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                      {this.state.movie.commingsoon === '0' ? 'Assista Agora' : 'Em breve'}
                      {' '}
                    </span>
                    {
                          !!this.state.movie.commingsoon === '0' && <i className="material-icons" style={{ color: '#FFFFFF' }}>play_arrow</i>
                        }
                  </Link>
                </HashRouter>
              )
            }

    </>
  )

  render() {
    return (
      <>
        <Header />
        {this.state.movie && (
          <>
            <div className="">
              <div id="app-show-movie" className="wrapper">
                <div id="hero" className="carousel slide carousel-fade">
                  <div className="carousel-inner">
                    <div
                      className="item active"
                      style={{
                        backgroundImage: `url(${this.state.movie.mv_banner})`,
                      }}
                    >
                      <div className="container">
                        <div
                          className="row blurb scrollme animateme "
                          data-when="exit"
                          data-from="0"
                          data-to="1"
                          data-opacity="0"
                          data-translatey="100"
                        >
                          <div
                            className="col-md-12 black-layer "
                            mouseover="showPlayer"
                            mouseout="hidePlayer"
                            data-when="exit"
                            data-from="0"
                            data-to="1"
                            data-opacity="0"
                            data-translatey="100"
                          >
                            <span className="title">{this.state.movie.categories.map((x, index) => `${x.category} ${index < this.state.movie.categories.length - 1 ? ',' : ''} `)}</span>

                            <h1>{this.state.movie.mv_title}</h1>

                            {
                              !!this.state.movie.mv_banner_text && <span className="shadow-text" dangerouslySetInnerHTML={{ __html: this.state.movie.mv_banner_text }} style={{ color: 'white' }} />
                            }
                            <br />
                            <div style={{ height: 30 }} />
                            <transition name="bounce">
                              {this.buttonsType()}
                            </transition>
                            <span className="title" />
                            <h1 className="shadow-text" />
                            <p className="shadow-text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container section single-movie">
                  <div className="row">
                    <div className="col-sm-12">

                      <h2>Informações do filme</h2>
                      <div className="row">
                        <div
                          className="col-sm-5"
                          style={{ justifyContent: 'space-around' }}
                        >
                          <img
                            src={this.state.movie.mv_poster}
                            alt=""
                            className="poster"

                          />
                          <div
                            className="buttons"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              border: '3px solid #faa82f',
                              padding: 3,
                            }}
                          >

                            {
                            !!this.state.movie.ranking[0]
                            && (
                            <span
                              className={`certificate ${
                                this.state.movie.ranking[0].ranking === 'Livre'
                                  ? 'certificate-0'
                                  : `certificate-${parseInt(
                                    this.state.movie.ranking[0].ranking
                                  )}`
                              }
                                  )}`}
                              title="Classificação"
                            >
                              {this.state.movie.ranking[0].ranking}
                            </span>
                            )
                          }
                            <div>
                              <i>Disponibilidade:</i>
                              <div>
                                {this.state.movie.movie_url
                                  ? 'Disponível para assistir'
                                  : 'Indisponível, volte em breve para atualizacões'}
                              </div>
                            </div>

                            <ul className="movie-info">

                              <li style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <i>País de origem:</i>
                                {this.state.movie.countries.map(x => (
                                  <div>
                                    <img src={x.country_image} style={{ height: 30, width: 40 }} />
                                    <span style={{ marginLeft: 10 }}>{x.country}</span>
                                    <span style={{ marginLeft: 10 }}>
                                      <i>
                                        (
                                        {x.country_code}
                                        )
                                      </i>
                                    </span>
                                  </div>
                                ))}
                              </li>

                              <li>
                                <i>Lançamento:</i>
                                {this.convertDate()}
                              </li>
                              <li>
                                <i>Duração:</i>
                                {`${this.state.movie.mv_duration} minutos`}
                              </li>
                              <li>
                                <i>Filme IMDB: </i>
                                <a href={this.state.movie.movie_imdb} target="__blank">
                                  {this.state.movie.movie_imdb}
                                </a>
                              </li>
                              <li>
                                <i>Idioma(s):</i>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                  {this.state.movie.languages.map(x => (
                                    <span>
                                      {x.languages}
                                    </span>
                                  ))}
                                </div>
                              </li>
                              <li>
                                <i>Categoria(s):</i>
                                <div style={{
                                  display: 'flex', flexDirection: 'column', marginLeft: 15, maxWidth: '40%',
                                }}
                                >
                                  {this.state.movie.categories.map(x => (
                                    <a href={`#/categoria/${x.url_name}`}>
                                      {x.category}
                                    </a>
                                  ))}
                                </div>

                              </li>

                            </ul>

                          </div>
                        </div>
                        <div
                          className="col-7"
                          style={{ overflow: 'hidden' }}
                        >

                          <div style={{ maxHeight: window.screen.height * 0.5, overflowY: 'auto' }}>

                            <div dangerouslySetInnerHTML={{ __html: this.state.movie.mv_sinopse ? this.state.movie.mv_sinopse : '' }} />

                          </div>

                          <div style={{ margin: 10 }}>

                            <ul className="movie-info">

                              <li>
                                <i>
                                  { this.state.movie.directors.length < 2 ? 'Diretor' : 'Diretores'}
                                  :
                                </i>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                  {this.state.movie.directors.map((x, index) => (
                                    <a href={`#/diretor/${x.slug}`}>
                                      {x.fullname}
                                      {' '}
                                      {index < (this.state.movie.directors.length - 1) ? ',' : ''}
                                    </a>
                                  ))}
                                </div>
                              </li>
                              <li>

                                <table>
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: 'justify' }}>
                                        <i>Atores</i>
                                      </th>
                                      <th style={{ textAlign: 'justify' }}>
                                        <i>Personagem</i>
                                      </th>
                                      <th style={{ textAlign: 'justify' }}>
                                        <i>Dublador</i>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.movie.casting_array.filter(x => x.profession !== 'Diretor').map((x, index) => (
                                        <tr>
                                          <td style={{ textAlign: 'justify' }}>
                                            <a href={`#/diretor/${x.slug}`}>
                                              {x.fullname}
                                            </a>
                                          </td>
                                          <td style={{ textAlign: 'justify' }}>
                                            {!!x.role && `${x.role}`}
                                          </td>
                                          <td style={{ textAlign: 'justify' }}>
                                            {!!x.dublador && `  ${x.dublador}`}
                                          </td>
                                        </tr>
                                      ))
                                      }
                                  </tbody>
                                </table>

                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              {this.state.pictureView && (
                <PictureModal open gallery={this.state.movie.mv_gallery} />
              )}
            </div>
            <Footer />
          </>
        )}
        <Loading
          open={this.state.loading}
          text="Estamos preparando o filme, aguarde..."
          theme="white"
        />
      </>
    );
  }
}
