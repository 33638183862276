import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <h6>Contato</h6>
                <ul>
                  <li>
                    <Link to="/faq">Perguntas Frequentes (FAQ)</Link>
                  </li>
                  <li>
                    <Link to="/parcerias">Parcerias e anúncios</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <h6>Sobre VamuVeTV</h6>
                <ul>
                  <li>
                    <Link to="/sobre">Quem somos ?</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <h6>Políticas</h6>
                <ul>
                  <li>
                    <Link to="/privacidade">Política de Privacidade</Link>
                  </li>
                  <li>
                    <Link to="/termos-de-uso">Termos de uso</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <h6>Conecte-se</h6>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/VamuveTV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook" />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/VamuveTV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter" />
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/VamuveTV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-youtube" />
                      YouTube
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/vamuvetv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram" />
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright">
              <strong>
                Copyright©
                {' '}
                {new Date().getFullYear()}
                {' '}
                VamuveTV Produções e Empreendimentos. Todos os direitos reservados.
                É proibida a cópia de quaisquer elementos desse site. 
              </strong>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
