import React, { useEffect, useState } from 'react';
import { Form, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Header from '../Header';
import Footer from '../Footer';
import { adminApi, siteApi } from '../../Axios';
import Loading from '../Loading';

// import './styles.css'

const animatedIcons = [
  'https://vamuvetv.com.br/assets/media/icones/animacao/animacao-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/animacao/animacao-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/animacao/animacao-james.png',
  'https://vamuvetv.com.br/assets/media/icones/animacao/animacao-red.png',
];
const adventureIcons = [
  'https://vamuvetv.com.br/assets/media/icones/aventura/aventura-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/aventura/aventura-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/aventura/aventura-james.png',
  'https://vamuvetv.com.br/assets/media/icones/aventura/aventura-red.png',
];
const comedyIcons = [
  'https://vamuvetv.com.br/assets/media/icones/comedia/comedia-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/comedia/comedia-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/comedia/comedia-james.png',
  'https://vamuvetv.com.br/assets/media/icones/comedia/comedia-red.png',
];
const dramaIcons = [
  'https://vamuvetv.com.br/assets/media/icones/drama/drama-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/drama/drama-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/drama/drama-james.png',
  'https://vamuvetv.com.br/assets/media/icones/drama/drama-red.png',
];
const scifiIcons = [
  'https://vamuvetv.com.br/assets/media/icones/ficcao/ficcao-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/ficcao/ficcao-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/ficcao/ficcao-james.png',
  'https://vamuvetv.com.br/assets/media/icones/ficcao/ficcao-red.png',
];
const romanceIcons = [
  'https://vamuvetv.com.br/assets/media/icones/romance/romance-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/romance/romance-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/romance/romance-james.png',
  'https://vamuvetv.com.br/assets/media/icones/romance/romance-red.png',
];
const suspenseIcons = [
  'https://vamuvetv.com.br/assets/media/icones/suspense/suspense-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/suspense/suspense-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/suspense/suspense-james.png',
  'https://vamuvetv.com.br/assets/media/icones/suspense/suspense-red.png',
];
const terrorIcons = [
  'https://vamuvetv.com.br/assets/media/icones/terror/terror-boogaloo.png',
  'https://vamuvetv.com.br/assets/media/icones/terror/terror-fred.png',
  'https://vamuvetv.com.br/assets/media/icones/terror/terror-james.png',
  'https://vamuvetv.com.br/assets/media/icones/terror/terror-red.png',
];
const seriadoIcons = [''];

export default function Category({ match }) {
  const firstLetters = [
    'A', 'B', 'C', 'D', 'E',
    'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y',
    'Z',
  ];
  const [titles, setTitles] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [icons, setIcons] = React.useState(null);
  const [searchInput, setSearchInput] = React.useState(null);

  const [screenTitle, setScreenTitle] = useState('');

  const getCategorys = async () => {
    adminApi.get('categorias')
      .then((resp) => {
        if (resp.data.status) {
          const c = resp.data.response.message;
          const found = c.filter(x => x.url_name === match.params.name)[0];
          if (found) setScreenTitle(found.category);
        }
      })
      .catch((error) => {
        alert(`Connect Error : ${error} DOI`);
      });
  };
  const [currentFilter, setCurrentFilter] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await getFilmsByCategory(match.params.name);
      await sortIcons(match.params.name);
      await getCategorys();
    }

    fetchData();
  }, [match.params.name, currentFilter]);

  function getFilmsByCategory(param, filter) {
    setLoading(true);
    const newTitles = [];
    const searchTerm = new URLSearchParams(window.location?.search).get('q');
    siteApi.get(`/movie/getAllCategoryMovies/${param}/${currentFilter || 'AVAILABLE'}${searchInput ? `?search=${searchInput}` : ''}`)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          const response = resp.data.response.message;
          if (filter) {
            if (filter === '2') {
              response.forEach((title) => {
                if (title.movie_url) {
                  newTitles.push(title);
                }
              });
              setTitles(newTitles);
            } else if (filter === '3') {
              response.forEach((title) => {
                if (!title.movie_url) {
                  newTitles.push(title);
                }
              });
              setTitles(newTitles);
            } else {
              setTitles(response);
            }
            setLoading(false);
          } else {
            setTitles(response);
            setLoading(false);
          }
        } else {
          setTitles(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(`Connect Error : ${error} UM`);
      });
  }

  const searchMovie = (e) => {
    e.preventDefault();
    getFilmsByCategory(match.params.name);
  };

  function sortIcons(param) {
    if (param === 'animacao') {
      setIcons(animatedIcons[Math.floor(Math.random() * animatedIcons.length)]);
    } else if (param === 'comedia') {
      setIcons(comedyIcons[Math.floor(Math.random() * comedyIcons.length)]);
    } else if (param === 'aventura') {
      setIcons(
        adventureIcons[Math.floor(Math.random() * adventureIcons.length)]
      );
    } else if (param === 'drama') {
      setIcons(dramaIcons[Math.floor(Math.random() * dramaIcons.length)]);
    } else if (param === 'ficcao-cientifica') {
      setIcons(scifiIcons[Math.floor(Math.random() * scifiIcons.length)]);
    } else if (param === 'romance') {
      setIcons(romanceIcons[Math.floor(Math.random() * romanceIcons.length)]);
    } else if (param === 'suspense') {
      setIcons(suspenseIcons[Math.floor(Math.random() * suspenseIcons.length)]);
    } else if (param === 'terror') {
      setIcons(terrorIcons[Math.floor(Math.random() * terrorIcons.length)]);
    } else if (param === 'seriado') {
      setIcons(seriadoIcons[Math.floor(Math.random() * seriadoIcons.length)]);
    }
  }

  const MovieItem = ({ movie, showBorder }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: showBorder ? '1px solid #3838384D' : '',
      }}
    >
      <span>{movie.mv_title}</span>
      <div>
        <Link to={`/filme/${movie.slug}`}>
          {movie.commingsoon === '1' ? 'Ler Mais' : 'Assistir'}
        </Link>
      </div>
    </div>
  );

  const MovieList = ({ titles }) => {
    const ITEMS_PER_PAGE = 25;
    const pages = [];
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState((titles.length / ITEMS_PER_PAGE).toFixed(0));
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const [alphabetFilter, setAlphabetFilter] = useState(null);
    const [sortedByAlphabet, setSortedByAlphabet] = useState({});

    useEffect(() => {
      const mapped = [];
      const filtered = alphabetFilter
        ? titles.filter((movie) => {
          const firstLetter = movie.mv_title.charAt(0).toUpperCase();
          if (firstLetters.includes(firstLetter)) {
            return firstLetter === alphabetFilter;
          }
          return !firstLetters.includes(alphabetFilter);
        })
        : titles;

      for (let i = currentPage * ITEMS_PER_PAGE; i < ((currentPage + 1) * ITEMS_PER_PAGE); i++) {
        mapped.push(filtered[i]);
      }

      setNumberOfPages((filtered.length / ITEMS_PER_PAGE).toFixed(0));

      setCurrentPageItems(mapped);

      const alphabetSorted = titles.reduce((memo, x) => {
        let firstLetter = x.mv_title.charAt(0).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        if (!firstLetters.includes(firstLetter)) firstLetter = '#';

        if (!memo[firstLetter]) memo[firstLetter] = [];
        memo[firstLetter].push(x);

        return memo;
      }, {});

      setSortedByAlphabet(alphabetSorted);
    }, [currentPage, alphabetFilter]);

    for (let i = 0; i < numberOfPages; i++) {
      pages.push(i + 1);
    }

    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <button
            type="button"
            onClick={() => {
              setCurrentPage(0);
              setAlphabetFilter(null);
            }}
            style={{
              paddingLeft: 5,
              marginBottom: 5,
              marginLeft: 5,
              paddingRight: 5,
              border: '1px solid #e76115',
              color: !alphabetFilter ? '#e76115' : '',
            }}
          >
            Todos
          </button>
          {
            Object.keys(sortedByAlphabet).sort((a, b) => a.localeCompare(b)).map(x => (
              <button
                type="button"
                onClick={() => {
                  setCurrentPage(0);
                  setAlphabetFilter(x.toUpperCase());
                }}
                style={{
                  paddingLeft: 5,
                  marginBottom: 5,
                  marginLeft: 5,
                  paddingRight: 5,
                  border: '1px solid #e76115',
                  color: alphabetFilter === x.toUpperCase() ? '#e76115' : '',
                }}
              >
                {
                  x
                }
              </button>
            ))
          }
        </div>

        <div style={{ height: 30 }} />
        {currentPageItems.filter((movie) => {
          if (!alphabetFilter) return movie;
          const firstLetter = movie?.mv_title?.charAt(0)?.toUpperCase();
          if (firstLetter && firstLetters.includes(firstLetter)) return firstLetter === alphabetFilter;
          return firstLetter && !firstLetters.includes(alphabetFilter);
        }).map((title, index) => <MovieItem movie={title} showBorder={(index + 1) < currentPageItems.length} />)}
        <ReactPaginate
          previousLabel="Anterior"
          nextLabel="Próxima"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={numberOfPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={e => setCurrentPage(e.selected)}
          initialPage={0}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div>
    );
  };

  function changeFilter(e) {
    const { value } = e.target;

    if (value === '2') {
      setCurrentFilter('AVAILABLE');
    } else if (value === '3') {
      setCurrentFilter('NOT_AVAILABLE');
    } else if (value === '1') {
      setCurrentFilter('ALL_AVAILABLE');
    }
  }

  return (
    <div>
      <Header />
      <div className="container section" style={{ paddingTop: '60px' }}>
        <div
          className="col-sm-4"
          style={{ paddingBottom: 50, flexDirection: 'flex-end' }}
        >
          <h4>Filtrar por : </h4>
          <Form>
            <Input type="select" name="filter" onChange={changeFilter}>
              <option value="2">Prontos para Assistir</option>
              <option value="3">Acervo</option>
              <option value="1">Todos os títulos</option>

            </Input>
          </Form>
        </div>
        <div
          className="col-sm-12"
          style={{ paddingBottom: 50, flexDirection: 'flex-end' }}
        >
          <h4>Buscar por : </h4>
          <form className="pull-left" role="search" method="POST" onSubmit={searchMovie}>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Pesquisar"
                name="q"
                onChange={e => setSearchInput(e.target.value)}
              />
              <span className="">
                <i className="fas fa-search" />
              </span>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-sm-12" id="afterHeader">
            <img style={{ maxHeight: '72px' }} src={icons} alt="" />
            <h2>{screenTitle}</h2>

            {titles && titles.length !== 0 ? (
              <div className="general">
                <MovieList titles={titles} />
              </div>
            ) : (
              <div className="alert alert-warning" role="alert">
                <p>
                  Sem títulos nessa categoria, tente utilizar nosso filtro para
                  encontrar o filme que deseja.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loading
        open={loading}
        text="Estamos preparando os filmes, aguarde..."
        theme="white"
      />
      <Footer />
    </div>
  );
}
